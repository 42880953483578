import {Navigate, Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {Login} from "./pages/Login";
import {Password} from "./pages/Password";
import {AuthProvider} from "./context/AuthContext";
import {useEffect} from "react";
import {StompSessionProvider} from "react-stomp-hooks";
import {config} from "./config";

function App() {
   useEffect(() => {
      const data = localStorage.getItem("data");

      if (data != null) localStorage.removeItem("data")
   }, []);

   return (
      <StompSessionProvider url={config.apiUrl + "/ws"}>
         <AuthProvider>
            <Router>
               <Routes>
                  <Route path="/" element={<Navigate to="/login" />}/>
                  <Route path={"/login"} element={<Login redirectMethod={"Phone"}/>}/>
                  <Route path={"/password"} element={<Password/>}/>
               </Routes>
            </Router>
         </AuthProvider>
      </StompSessionProvider>
   )
}

export default App;
