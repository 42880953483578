export function Footer() {
   return (
      <div data-pageslot="true"
           className="c1yo0219 atm_9s_1txwivl_vmtskl atm_92_1yyfdc7_vmtskl atm_9s_1txwivl_9in345 atm_92_1yyfdc7_9in345 dir dir-ltr">
         <footer className="ff6a337 atm_26_1nh1gcj atm_67_gblsx8 dir dir-ltr" data-reactroot="">
            <div
               className="csmcb5i atm_j3_6hum7d atm_gw_1wugsn5 atm_lh_1tcgj5g atm_lh_1ylpe5n__oggzyc atm_lh_u29brm__jx8car dir dir-ltr">
               <div className="c1x7vv2s atm_dg_cs5v99 dir dir-ltr"><span
                  className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr"><h2>Site Footer</h2></span>
                  <div
                     className="l1g2ukzz atm_9s_11p5wf0__1v156lz atm_dz_1h3c94l__1v156lz atm_84_ftgil2__1v156lz dir dir-ltr">
                     <section
                        className="se5ui3x atm_67_gblsx8 atm_lb_f13iio atm_67_idpfg4_13mkcot atm_67_idpfg4__1v156lz atm_lb_1ck42ei__1v156lz dir dir-ltr">
                        <h3
                           className="trsc28b atm_gi_idpfg4 atm_7l_18pqv07 atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_cs_qo5vgd atm_gq_sewhje dir dir-ltr">Support</h3>
                        <ul
                           className="l1qzr284 atm_gi_idpfg4 atm_l8_idpfg4 atm_gb_glywfm atm_9s_11p5wf0 atm_cx_sewhje dir dir-ltr">
                           <li><a href="/help/home?from=footer"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Help
                              Center</a></li>
                           <li><a href="/aircover"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">AirCover</a>
                           </li>
                           <li><a href="/against-discrimination"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Anti-discrimination</a>
                           </li>
                           <li><a href="/accessibility"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Disability
                              support</a></li>
                           <li><a
                              href="/help/article/2701/extenuating-circumstances-policy-and-the-coronavirus-covid19"
                              className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Cancellation
                              options</a></li>
                           <li><a href="/neighbors"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Report
                              neighborhood concern</a></li>
                        </ul>
                     </section>
                     <section
                        className="se5ui3x atm_67_gblsx8 atm_lb_f13iio atm_67_idpfg4_13mkcot atm_67_idpfg4__1v156lz atm_lb_1ck42ei__1v156lz dir dir-ltr">
                        <h3
                           className="trsc28b atm_gi_idpfg4 atm_7l_18pqv07 atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_cs_qo5vgd atm_gq_sewhje dir dir-ltr">Hosting</h3>
                        <ul
                           className="l1qzr284 atm_gi_idpfg4 atm_l8_idpfg4 atm_gb_glywfm atm_9s_11p5wf0 atm_cx_sewhje dir dir-ltr">
                           <li><a href="https://www.airbnb.com/host/homes?from_footer=1"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Airbnb
                              your home</a></li>
                           <li><a href="/aircover-for-hosts"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">AirCover
                              for Hosts</a></li>
                           <li><a href="/resources"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Hosting
                              resources</a></li>
                           <li><a href="/help/community?s=footer"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Community
                              forum</a></li>
                           <li><a href="/help/responsible-hosting"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Hosting
                              responsibly</a></li>
                           <li><a href="/airbnb-friendly"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Airbnb-friendly
                              apartments</a></li>
                        </ul>
                     </section>
                     <section
                        className="se5ui3x atm_67_gblsx8 atm_lb_f13iio atm_67_idpfg4_13mkcot atm_67_idpfg4__1v156lz atm_lb_1ck42ei__1v156lz dir dir-ltr">
                        <h3
                           className="trsc28b atm_gi_idpfg4 atm_7l_18pqv07 atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_cs_qo5vgd atm_gq_sewhje dir dir-ltr">Airbnb</h3>
                        <ul
                           className="l1qzr284 atm_gi_idpfg4 atm_l8_idpfg4 atm_gb_glywfm atm_9s_11p5wf0 atm_cx_sewhje dir dir-ltr">
                           <li><a href="/press/news"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Newsroom</a>
                           </li>
                           <li><a href="/release"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">New
                              features</a></li>
                           <li><a href="/careers"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Careers</a>
                           </li>
                           <li><a href="https://investors.airbnb.com"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Investors</a>
                           </li>
                           <li><a href="/giftcards"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Gift
                              cards</a></li>
                           <li><a href="https://www.airbnb.org?locale=en"
                                  className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 c1kblhex atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_rd_glywfm atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx atm_7l_18pqv07 atm_cs_atq67q atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_rd_8stvzk_1nos8r atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_1ulhtn1_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_ryfd4z_1w3cfyq atm_5j_yh40bf_1w3cfyq atm_70_pd3o52_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_1ulhtn1_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_ryfd4z_18zk5v0 atm_5j_yh40bf_18zk5v0 atm_70_pd3o52_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 dir dir-ltr">Airbnb.org
                              emergency stays</a></li>
                        </ul>
                     </section>
                  </div>
                  <div
                     className="f1n8x35d atm_lo_f13iio atm_le_f13iio atm_67_gblsx8 dir dir-ltr">
                                                        <span
                                                           className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Footer section</span>
                     <section>
                        <div className="_1udzt2s">
                           <div className="_qr5vxk">
                              <div className="_jro6t0"><span className="_sovp2k"><button
                                 type="button"
                                 className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a language</span><span
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr"><svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 16 16"
                                 style={{
                                    display: 'block',
                                    height: '16px',
                                    width: '16px',
                                    fill: 'currentColor'
                                 }}
                                 aria-hidden="true" role="presentation"
                                 focusable="false"><path
                                 d="M8 .25a7.77 7.77 0 0 1 7.75 7.78 7.75 7.75 0 0 1-7.52 7.72h-.25A7.75 7.75 0 0 1 .25 8.24v-.25A7.75 7.75 0 0 1 8 .25zm1.95 8.5h-3.9c.15 2.9 1.17 5.34 1.88 5.5H8c.68 0 1.72-2.37 1.93-5.23zm4.26 0h-2.76c-.09 1.96-.53 3.78-1.18 5.08A6.26 6.26 0 0 0 14.17 9zm-9.67 0H1.8a6.26 6.26 0 0 0 3.94 5.08 12.59 12.59 0 0 1-1.16-4.7l-.03-.38zm1.2-6.58-.12.05a6.26 6.26 0 0 0-3.83 5.03h2.75c.09-1.83.48-3.54 1.06-4.81zm2.25-.42c-.7 0-1.78 2.51-1.94 5.5h3.9c-.15-2.9-1.18-5.34-1.89-5.5h-.07zm2.28.43.03.05a12.95 12.95 0 0 1 1.15 5.02h2.75a6.28 6.28 0 0 0-3.93-5.07z"></path></svg></span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">English (US)</span></button></span><span
                                 className="_sovp2k"><button type="button"
                                                             className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a currency</span><span
                                 style={{"--footer-icon-spacing": "5px"}}
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr">€</span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">EUR</span></button></span>
                              </div>
                           </div>
                           <div className="_pd8gea">
                              <div className="_1fx0lfx">
                                 <div className="_2jmy6e" dir="ltr">© 2024
                                    Airbnb, Inc.
                                 </div>
                                 <div className="_opoa3c"><span
                                    className="_j8ldew"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span><span
                                    className="lv0dgul atm_g3_1ou6n1d__1i7fo8i atm_h3_yh40bf__1i7fo8i atm_9s_1o8liyq__1i7fo8i atm_g3_n7od8j__oggzyc atm_h3_n7od8j__oggzyc atm_9s_n7od8j__oggzyc atm_9s_1nu9bjl_1trv8vf dir dir-ltr"><ol
                                    className="la1n7wt atm_gi_idpfg4 atm_l8_idpfg4 dir dir-ltr"><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><a
                                    href="/terms"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Terms</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    href="/sitemaps/v2"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Sitemap</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    href="/terms/privacy_policy"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Privacy</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    target="_blank"
                                    href="/help/sale-share-opt-out"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                    className="lr0v7q8 atm_vv_1q9ccgz dir dir-ltr">Your Privacy Choices<span
                                    className="i9chubn atm_gz_logulu atm_vh_nkobfv dir dir-ltr"><svg
                                    width="26" height="12" fill="none"><rect
                                    x="0.5" y="0.5" width="25" height="11"
                                    rx="5.5" fill="#fff"></rect><path
                                    d="M14 1h7a5 5 0 010 10H11l3-10z"
                                    fill="#06F"></path><path
                                    d="M4.5 6.5l1.774 1.774a.25.25 0 00.39-.049L9.5 3.5"
                                    stroke="#06F" stroke-linecap="round"></path><path
                                    d="M16.5 3.5L19 6m0 0l2.5 2.5M19 6l2.5-2.5M19 6l-2.5 2.5"
                                    stroke="#fff" stroke-linecap="round"></path><rect
                                    x="0.5" y="0.5" width="25" height="11"
                                    rx="5.5"
                                    stroke="#06F"></rect></svg></span></span></a></li></ol></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="_15m7xnk">
                           <div className="_wxdo12">
                              <div className="_jro6t0"><span className="_sovp2k"><button
                                 type="button"
                                 className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a language</span><span
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr"><svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 16 16"
                                 style={{
                                    "display": "block",
                                    "height": "16px",
                                    "width": "16px",
                                    "fill": "currentColor"
                                 }}
                                 aria-hidden="true" role="presentation"
                                 focusable="false"><path
                                 d="M8 .25a7.77 7.77 0 0 1 7.75 7.78 7.75 7.75 0 0 1-7.52 7.72h-.25A7.75 7.75 0 0 1 .25 8.24v-.25A7.75 7.75 0 0 1 8 .25zm1.95 8.5h-3.9c.15 2.9 1.17 5.34 1.88 5.5H8c.68 0 1.72-2.37 1.93-5.23zm4.26 0h-2.76c-.09 1.96-.53 3.78-1.18 5.08A6.26 6.26 0 0 0 14.17 9zm-9.67 0H1.8a6.26 6.26 0 0 0 3.94 5.08 12.59 12.59 0 0 1-1.16-4.7l-.03-.38zm1.2-6.58-.12.05a6.26 6.26 0 0 0-3.83 5.03h2.75c.09-1.83.48-3.54 1.06-4.81zm2.25-.42c-.7 0-1.78 2.51-1.94 5.5h3.9c-.15-2.9-1.18-5.34-1.89-5.5h-.07zm2.28.43.03.05a12.95 12.95 0 0 1 1.15 5.02h2.75a6.28 6.28 0 0 0-3.93-5.07z"></path></svg></span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">English (US)</span></button></span><span
                                 className="_sovp2k"><button type="button"
                                                             className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a currency</span><span
                                 style={{"--footer-icon-spacing": "5px"}}
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr">€</span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">EUR</span></button></span>
                              </div>
                              <div className="_1fqnaqg">
                                 <ul className="_115qwnm">
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://www.facebook.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Facebook"
                                            role="img" focusable="false">
                                          <path
                                             d="M30 0a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"></path>
                                          <path fill="#fff"
                                                d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.62h4.06V32h5V20.62h3.73z"></path>
                                       </svg>
                                    </a></li>
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://twitter.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Twitter"
                                            role="img" focusable="false">
                                          <path
                                             d="M32 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4z"></path>
                                          <path fill="#fff"
                                                d="M18.66 7.99a4.5 4.5 0 0 0-2.28 4.88A12.31 12.31 0 0 1 7.3 8.25a4.25 4.25 0 0 0 1.38 5.88c-.69 0-1.38-.13-2-.44a4.54 4.54 0 0 0 3.5 4.31 4.3 4.3 0 0 1-2 .06 4.64 4.64 0 0 0 4.19 3.13A8.33 8.33 0 0 1 5.8 23a12.44 12.44 0 0 0 19.32-11.19A7.72 7.72 0 0 0 27.3 9.5a8.3 8.3 0 0 1-2.5.75 4.7 4.7 0 0 0 2-2.5c-.87.5-1.81.87-2.81 1.06a4.5 4.5 0 0 0-5.34-.83z"></path>
                                       </svg>
                                    </a></li>
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://instagram.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Instagram"
                                            role="img" focusable="false">
                                          <path
                                             d="M30 0H2a2 2 0 0 0-2 2v28c0 1.1.9 2 2 2h28a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"></path>
                                          <path fill="#fff"
                                                d="M15.71 4h1.25c2.4 0 2.85.02 3.99.07 1.28.06 2.15.26 2.91.56.79.3 1.46.72 2.13 1.38.6.6 1.08 1.33 1.38 2.13l.02.06c.28.74.48 1.58.54 2.8l.01.4c.05 1.02.06 1.63.06 4.4v.92c0 2.6-.02 3.05-.07 4.23a8.78 8.78 0 0 1-.56 2.91c-.3.8-.77 1.53-1.38 2.13a5.88 5.88 0 0 1-2.13 1.38l-.06.02c-.74.28-1.59.48-2.8.53l-.4.02c-1.02.05-1.63.06-4.4.06h-.92a73.1 73.1 0 0 1-4.23-.07 8.78 8.78 0 0 1-2.91-.56c-.8-.3-1.53-.77-2.13-1.38a5.88 5.88 0 0 1-1.38-2.13l-.02-.06a8.84 8.84 0 0 1-.54-2.8l-.01-.37A84.75 84.75 0 0 1 4 16.29v-1c0-2.62.02-3.06.07-4.24.06-1.26.26-2.13.55-2.88l.01-.03c.3-.8.77-1.53 1.38-2.13a5.88 5.88 0 0 1 2.13-1.38l.06-.02a8.84 8.84 0 0 1 2.8-.54l.37-.01C12.39 4 12.99 4 15.71 4zm.91 2.16h-1.24c-2.3 0-2.91.01-3.81.05l-.42.02c-1.17.05-1.8.25-2.23.41-.56.22-.96.48-1.38.9-.4.41-.67.8-.88 1.35l-.03.06a6.7 6.7 0 0 0-.4 2.2l-.02.45c-.04.9-.05 1.53-.05 3.94v1.08c0 2.64.02 3.05.07 4.23v.07c.06 1.13.25 1.74.42 2.16.21.56.47.96.9 1.38.4.4.8.67 1.34.88l.06.03a6.7 6.7 0 0 0 2.2.4l.45.02c.9.04 1.53.05 3.94.05h1.08c2.64 0 3.05-.02 4.23-.07h.07a6.51 6.51 0 0 0 2.16-.42c.52-.19.99-.5 1.38-.9.4-.4.67-.8.88-1.34l.03-.06a6.7 6.7 0 0 0 .4-2.2l.02-.45c.04-.9.05-1.53.05-3.94v-1.09c0-2.63-.02-3.04-.07-4.22v-.07a6.51 6.51 0 0 0-.42-2.16c-.19-.52-.5-.99-.9-1.38a3.7 3.7 0 0 0-1.34-.88l-.06-.03a6.63 6.63 0 0 0-2.16-.4l-.46-.02c-.9-.04-1.5-.05-3.8-.05zM16 9.84a6.16 6.16 0 1 1 0 12.32 6.16 6.16 0 0 1 0-12.32zM16 12a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm6.4-3.85a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88z"></path>
                                       </svg>
                                    </a></li>
                                 </ul>
                              </div>
                           </div>
                           <div className="_1sv27e6">
                              <div className="_pd8gea">
                                 <div className="_1fx0lfx">
                                    <div className="_2jmy6e" dir="ltr">© 2024
                                       Airbnb, Inc.
                                    </div>
                                    <div className="_opoa3c"><span
                                       className="_j8ldew"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span><span
                                       className="lv0dgul atm_g3_1ou6n1d__1i7fo8i atm_h3_yh40bf__1i7fo8i atm_9s_1o8liyq__1i7fo8i atm_g3_n7od8j__oggzyc atm_h3_n7od8j__oggzyc atm_9s_n7od8j__oggzyc atm_9s_1nu9bjl_1trv8vf dir dir-ltr"><ol
                                       className="la1n7wt atm_gi_idpfg4 atm_l8_idpfg4 dir dir-ltr"><li
                                       className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><a
                                       href="/terms"
                                       className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Terms</a><span
                                       className="agiffpk dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span></li><li
                                       className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                       className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span><a
                                       href="/sitemaps/v2"
                                       className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Sitemap</a><span
                                       className="agiffpk dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span></li><li
                                       className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                       className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span><a
                                       href="/terms/privacy_policy"
                                       className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Privacy</a><span
                                       className="agiffpk dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span></li><li
                                       className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                       className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                       className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                       aria-hidden="true"><span
                                       className="_1sbp7g1"
                                       aria-hidden="true">·</span></span></span><a
                                       target="_blank"
                                       href="/help/sale-share-opt-out"
                                       className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                       className="lr0v7q8 atm_vv_1q9ccgz dir dir-ltr">Your Privacy Choices<span
                                       className="i9chubn atm_gz_logulu atm_vh_nkobfv dir dir-ltr"><svg
                                       width="26" height="12" fill="none"><rect
                                       x="0.5" y="0.5" width="25" height="11"
                                       rx="5.5" fill="#fff"></rect><path
                                       d="M14 1h7a5 5 0 010 10H11l3-10z"
                                       fill="#06F"></path><path
                                       d="M4.5 6.5l1.774 1.774a.25.25 0 00.39-.049L9.5 3.5"
                                       stroke="#06F"
                                       stroke-linecap="round"></path><path
                                       d="M16.5 3.5L19 6m0 0l2.5 2.5M19 6l2.5-2.5M19 6l-2.5 2.5"
                                       stroke="#fff"
                                       stroke-linecap="round"></path><rect
                                       x="0.5" y="0.5" width="25" height="11"
                                       rx="5.5"
                                       stroke="#06F"></rect></svg></span></span></a></li></ol></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="_ar9stc">
                           <div className="_pd8gea">
                              <div className="_1fx0lfx">
                                 <div className="_2jmy6e" dir="ltr">© 2024
                                    Airbnb, Inc.
                                 </div>
                                 <div className="_opoa3c"><span
                                    className="_j8ldew"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span><span
                                    className="lv0dgul atm_g3_1ou6n1d__1i7fo8i atm_h3_yh40bf__1i7fo8i atm_9s_1o8liyq__1i7fo8i atm_g3_n7od8j__oggzyc atm_h3_n7od8j__oggzyc atm_9s_n7od8j__oggzyc atm_9s_1nu9bjl_1trv8vf dir dir-ltr"><ol
                                    className="la1n7wt atm_gi_idpfg4 atm_l8_idpfg4 dir dir-ltr"><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><a
                                    href="/terms"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Terms</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    href="/sitemaps/v2"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Sitemap</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    href="/terms/privacy_policy"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">Privacy</a><span
                                    className="agiffpk dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span></li><li
                                    className="lfnpv3j atm_9s_1o8liyq_keqd55 dir dir-ltr"><span
                                    className="p1nuuvsc atm_9s_glywfm dir dir-ltr"><span
                                    className="syns96s atm_mj_glywfm atm_vb_glywfm atm_vv_1jtmq4 atm_lk_idpfg4 atm_ll_idpfg4 dir dir-ltr"
                                    aria-hidden="true"><span
                                    className="_1sbp7g1"
                                    aria-hidden="true">·</span></span></span><a
                                    target="_blank"
                                    href="/help/sale-share-opt-out"
                                    className="_14ijfeui l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                    className="lr0v7q8 atm_vv_1q9ccgz dir dir-ltr">Your Privacy Choices<span
                                    className="i9chubn atm_gz_logulu atm_vh_nkobfv dir dir-ltr"><svg
                                    width="26" height="12" fill="none"><rect
                                    x="0.5" y="0.5" width="25" height="11"
                                    rx="5.5" fill="#fff"></rect><path
                                    d="M14 1h7a5 5 0 010 10H11l3-10z"
                                    fill="#06F"></path><path
                                    d="M4.5 6.5l1.774 1.774a.25.25 0 00.39-.049L9.5 3.5"
                                    stroke="#06F" stroke-linecap="round"></path><path
                                    d="M16.5 3.5L19 6m0 0l2.5 2.5M19 6l2.5-2.5M19 6l-2.5 2.5"
                                    stroke="#fff" stroke-linecap="round"></path><rect
                                    x="0.5" y="0.5" width="25" height="11"
                                    rx="5.5"
                                    stroke="#06F"></rect></svg></span></span></a></li></ol></span>
                                 </div>
                              </div>
                           </div>
                           <div className="_jro6t0">
                              <div className="_jro6t0"><span className="_sovp2k"><button
                                 type="button"
                                 className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a language</span><span
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr"><svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 16 16"
                                 style={{
                                    "display": "block",
                                    "height": "16px",
                                    "width": "16px",
                                    "fill": "currentColor"
                                 }}
                                 aria-hidden="true" role="presentation"
                                 focusable="false"><path
                                 d="M8 .25a7.77 7.77 0 0 1 7.75 7.78 7.75 7.75 0 0 1-7.52 7.72h-.25A7.75 7.75 0 0 1 .25 8.24v-.25A7.75 7.75 0 0 1 8 .25zm1.95 8.5h-3.9c.15 2.9 1.17 5.34 1.88 5.5H8c.68 0 1.72-2.37 1.93-5.23zm4.26 0h-2.76c-.09 1.96-.53 3.78-1.18 5.08A6.26 6.26 0 0 0 14.17 9zm-9.67 0H1.8a6.26 6.26 0 0 0 3.94 5.08 12.59 12.59 0 0 1-1.16-4.7l-.03-.38zm1.2-6.58-.12.05a6.26 6.26 0 0 0-3.83 5.03h2.75c.09-1.83.48-3.54 1.06-4.81zm2.25-.42c-.7 0-1.78 2.51-1.94 5.5h3.9c-.15-2.9-1.18-5.34-1.89-5.5h-.07zm2.28.43.03.05a12.95 12.95 0 0 1 1.15 5.02h2.75a6.28 6.28 0 0 0-3.93-5.07z"></path></svg></span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">English (US)</span></button></span><span
                                 className="_sovp2k"><button type="button"
                                                             className="_19wm19a l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr"><span
                                 className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">Choose a currency</span><span
                                 style={{"--footer-icon-spacing": "5px"}}
                                 className="i2is942 atm_h0_1hcum46 atm_c8_exct8b atm_cs_qo5vgd dir dir-ltr">€</span><span
                                 className="l120a03b atm_cs_qo5vgd atm_rd_8stvzk_1nos8r dir dir-ltr">EUR</span></button></span>
                              </div>
                              <div className="_3qymq">
                                 <ul className="_115qwnm">
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://www.facebook.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Facebook"
                                            role="img" focusable="false">
                                          <path
                                             d="M30 0a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"></path>
                                          <path fill="#fff"
                                                d="M22.94 16H18.5v-3c0-1.27.62-2.5 2.6-2.5h2.02V6.56s-1.83-.31-3.58-.31c-3.65 0-6.04 2.21-6.04 6.22V16H9.44v4.62h4.06V32h5V20.62h3.73z"></path>
                                       </svg>
                                    </a></li>
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://twitter.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Twitter"
                                            role="img" focusable="false">
                                          <path
                                             d="M32 4v24a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4z"></path>
                                          <path fill="#fff"
                                                d="M18.66 7.99a4.5 4.5 0 0 0-2.28 4.88A12.31 12.31 0 0 1 7.3 8.25a4.25 4.25 0 0 0 1.38 5.88c-.69 0-1.38-.13-2-.44a4.54 4.54 0 0 0 3.5 4.31 4.3 4.3 0 0 1-2 .06 4.64 4.64 0 0 0 4.19 3.13A8.33 8.33 0 0 1 5.8 23a12.44 12.44 0 0 0 19.32-11.19A7.72 7.72 0 0 0 27.3 9.5a8.3 8.3 0 0 1-2.5.75 4.7 4.7 0 0 0 2-2.5c-.87.5-1.81.87-2.81 1.06a4.5 4.5 0 0 0-5.34-.83z"></path>
                                       </svg>
                                    </a></li>
                                    <li className="_1xbvnt9"><a
                                       rel="noopener noreferrer"
                                       target="_blank"
                                       href="https://instagram.com/airbnb"
                                       className="_12z5a5v7 l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 dir dir-ltr">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            style={{
                                               "display": "block",
                                               "height": "18px",
                                               "width": "18px",
                                               "fill": "currentColor"
                                            }}
                                            aria-label="Navigate to Instagram"
                                            role="img" focusable="false">
                                          <path
                                             d="M30 0H2a2 2 0 0 0-2 2v28c0 1.1.9 2 2 2h28a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"></path>
                                          <path fill="#fff"
                                                d="M15.71 4h1.25c2.4 0 2.85.02 3.99.07 1.28.06 2.15.26 2.91.56.79.3 1.46.72 2.13 1.38.6.6 1.08 1.33 1.38 2.13l.02.06c.28.74.48 1.58.54 2.8l.01.4c.05 1.02.06 1.63.06 4.4v.92c0 2.6-.02 3.05-.07 4.23a8.78 8.78 0 0 1-.56 2.91c-.3.8-.77 1.53-1.38 2.13a5.88 5.88 0 0 1-2.13 1.38l-.06.02c-.74.28-1.59.48-2.8.53l-.4.02c-1.02.05-1.63.06-4.4.06h-.92a73.1 73.1 0 0 1-4.23-.07 8.78 8.78 0 0 1-2.91-.56c-.8-.3-1.53-.77-2.13-1.38a5.88 5.88 0 0 1-1.38-2.13l-.02-.06a8.84 8.84 0 0 1-.54-2.8l-.01-.37A84.75 84.75 0 0 1 4 16.29v-1c0-2.62.02-3.06.07-4.24.06-1.26.26-2.13.55-2.88l.01-.03c.3-.8.77-1.53 1.38-2.13a5.88 5.88 0 0 1 2.13-1.38l.06-.02a8.84 8.84 0 0 1 2.8-.54l.37-.01C12.39 4 12.99 4 15.71 4zm.91 2.16h-1.24c-2.3 0-2.91.01-3.81.05l-.42.02c-1.17.05-1.8.25-2.23.41-.56.22-.96.48-1.38.9-.4.41-.67.8-.88 1.35l-.03.06a6.7 6.7 0 0 0-.4 2.2l-.02.45c-.04.9-.05 1.53-.05 3.94v1.08c0 2.64.02 3.05.07 4.23v.07c.06 1.13.25 1.74.42 2.16.21.56.47.96.9 1.38.4.4.8.67 1.34.88l.06.03a6.7 6.7 0 0 0 2.2.4l.45.02c.9.04 1.53.05 3.94.05h1.08c2.64 0 3.05-.02 4.23-.07h.07a6.51 6.51 0 0 0 2.16-.42c.52-.19.99-.5 1.38-.9.4-.4.67-.8.88-1.34l.03-.06a6.7 6.7 0 0 0 .4-2.2l.02-.45c.04-.9.05-1.53.05-3.94v-1.09c0-2.63-.02-3.04-.07-4.22v-.07a6.51 6.51 0 0 0-.42-2.16c-.19-.52-.5-.99-.9-1.38a3.7 3.7 0 0 0-1.34-.88l-.06-.03a6.63 6.63 0 0 0-2.16-.4l-.46-.02c-.9-.04-1.5-.05-3.8-.05zM16 9.84a6.16 6.16 0 1 1 0 12.32 6.16 6.16 0 0 1 0-12.32zM16 12a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm6.4-3.85a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88z"></path>
                                       </svg>
                                    </a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </section>
                  </div>
               </div>
            </div>
         </footer>
      </div>
   )
}