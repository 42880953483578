import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Loading} from "./pages/Loading";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     {localStorage.getItem("infinity") === null ? <App/> : <Loading/>}
  </React.StrictMode>
);
