import axios from 'axios';

export class ApiService {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }
    async request(url, method = 'GET', data = null, headers = {}) {
        try {
            return await axios({
                method,
                url: this.baseURL + url,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*',
                    ...headers,
                },
                data: data ? new URLSearchParams(data).toString() : null,
            });
        } catch (error) {
            return error;
        }
    }
    async get(url, headers = {}) {
        return this.request(url, 'GET', null, headers);
    }
    async post(url, data, headers = {}) {
        return this.request(url, 'POST', data, headers);
    }
    async put(url, data, headers = {}) {
        return this.request(url, 'PUT', data, headers);
    }
    async delete(url, headers = {}) {
        return this.request(url, 'DELETE', null, headers);
    }
}

