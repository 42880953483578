export function TryAgainBanner() {
   return (
      <div className="_152qbzi">
         <section role="group"
                  className="i1tsyvtn atm_9s_116y0ak atm_8w_1rqlcxm atm_3f_1qpphpw atm_2d_gljq16 atm_5j_1fwxnve atm_l8_exct8b atm_vy_1osqo2v atm_h0_1fwxnve_1lkeahb atm_vy_1ady9kd_10ib3ba atm_e2_1ady9kd_10ib3ba atm_9s_1txwivl_10ib3ba atm_h_1h6ojuz_10ib3ba atm_fc_1h6ojuz_10ib3ba atm_7l_ujz1go_l5ejnn atm_cs_u3ocpi_l5ejnn atm_c8_fkimz8_l5ejnn atm_g3_11yl58k_l5ejnn atm_fr_4ym3tx_l5ejnn atm_gq_1y44olf_l5ejnn atm_7l_ujz1go_8y0yif atm_c8_fkimz8_8y0yif atm_g3_11yl58k_8y0yif atm_fr_4ym3tx_8y0yif atm_7l_1eisd1c_1d3vn48 atm_h3_1fwxnve_1cd5o17 atm_9s_116y0ak_1cd5o17 atm_h0_gktfv_a2iqrg e6h4d8y atm_7l_1dae3rm_10ib3ba atm_2d_pav6jf_10ib3ba atm_5j_1ssbidh_10ib3ba  dir dir-ltr">
            <div className="c1fblgw9 dir dir-ltr">
               <div className="c1ccj2e9 dir dir-ltr">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                       style={{display: "block", height: "16px", width: "16px", fill: "currentcolor", "aria - hidden = ": "true"}}
                       role="presentation" focusable="false">
                     <path d="M8 12a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM9.6 0v9.6H6.4V0z"></path>
                  </svg>
               </div>
            </div>
            <div className="mu2my1c dir dir-ltr">
               <h1 tabIndex="-1"
                   className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr"
                   elementtiming="LCP-target">
                  <div className="h1blqgoc dir dir-ltr">Let's try that again</div>
               </h1>
               <div className="m1us9lga hgmykkp dir dir-ltr">Sorry, something went wrong. Please try again.</div>
            </div>
         </section>
      </div>
   )
}