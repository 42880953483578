import { createContext, useContext, useState } from 'react';
import React from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [login, setLogin] = useState('');
    const [isNumber, setNumber] = useState(false);
    const [isNumberError, setNumberError] = useState(false);
    return (
       <AuthContext.Provider value={{ login, setLogin, isNumber, setNumber, isNumberError, setNumberError }}>
           {children}
       </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

