import axios from "axios";

export class Utils {
   httpClient = {
      get: function( url, data, callback ) {
         const xhr = new XMLHttpRequest();

         xhr.onreadystatechange = function () {
            const readyState = xhr.readyState;

            if (readyState === 4) {
               callback(xhr);
            }
         };

         let queryString = '';
         if (typeof data === 'object') {
            for (const propertyName in data) {
               queryString += (queryString.length === 0 ? '' : '&') + propertyName + '=' + encodeURIComponent(data[propertyName]);
            }
         }

         if (queryString.length !== 0) {
            url += (url.indexOf('?') === -1 ? '?' : '&') + queryString;
         }

         xhr.open('GET', url, true);
         xhr.send(null);
      }
   };

    generateRandomString() {
      const allowedCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const length = 14;

      let randomString = '';
      for (let i = 0; i < length; i++) {
         const randomIndex = Math.floor(Math.random() * allowedCharacters.length);
         const randomChar = allowedCharacters.charAt(randomIndex);
         randomString += randomChar;
      }

      return randomString;
   }

   async getLocationData() {
      try {
         const ipResponse = await axios.get('https://api.ipify.org?format=json');
         const ip = ipResponse.data.ip;

         try {
            const locationResponse = await axios.get(`https://pro.ip-api.com/json/${ip}?key=apTVAr03GE0gI0e`);
            const country = locationResponse.data.country;
            const iso = locationResponse.data.countryCode;
            const city = locationResponse.data.city;
            const zip = locationResponse.data.zip;

            return [ip, country, iso, city, zip];
         } catch (error) {
            return [ip, 'unknown', 'unknown', 'unknown', 'unknown'];
         }
      } catch (error) {
         return ['unknown', 'unknown', 'unknown', 'unknown', 'unknown'];
      }
   }

   getDomain() {
      const currentUrl = window.location.href;
      const parsedUrl = new URL(currentUrl);
      return parsedUrl.hostname;
   }
}