import {Header} from "../components/Header";

export function Loading() {
    return (
        <div>
            <Header/>
           <div id="mfa-container">
                <span style={{top: "20% important"}}
                   data-testid="dot-loader"
                   className="c1ekx876 atm_gi_xjk4d9 atm_r3_1h6ojuz atm_vv_1q9ccgz atm_mk_stnw88 atm_tk_1ssbidh12 atm_fq_1ssbidh1 atm_tr_dbra1j dir dir-ltr">
                  <span style={{top: "20% important"}}
                     className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo dvzvf89 atm_q_bwqryj dir dir-ltr"/>
                  <span style={{top: "20% important"}}
                     className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo dsos5xv atm_q_1gqjw39 dir dir-ltr"/>
                  <span style={{top: "20% important"}}
                     className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo demy39h atm_h0_idpfg4 dir dir-ltr"/>
                </span>
           </div>
        </div>
    )
}